// NOTE: The code about the hold music needs to be refactored. Now it is made only to work.

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import LoaderFull from 'loader-full'
import TextField from 'pdc-text-field'
import Menu from 'pdc-menu'
import Button from 'button'
import AudioManager from 'audio-manager'
import { XIcon } from 'pdc-svg-icons'
import SelectionControl from 'selection-control'
import Prompt from 'pdc-prompt'
import { theme } from 'get-theme'
import PropTypes from 'prop-types'

import api from '../../util/api_v5'
import styles from './styles'
import { updateVoicemail, updateHoldMusic, updateSettings } from '../../actions/general-settings'
import { stateOptions, provinceOptions, conutryOptions } from './util'
import { withStyles } from '@material-ui/core'
import TimezoneField from 'timezone-field'
import RemoteConfigValue from 'remote-config-value'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
import CancelAccount from '../../../../account/src/components/CancelAccount/CancelAccount.js'

const mapStateToProps = state => ({
    voicemail: state.voicemail,
    holdMusic: state.holdMusic,
    settings: state.settings,
    smallView: state.smallView
})

const mapDispatchToProps = dispatch => ({
    updateVoicemail: voicemail => dispatch(updateVoicemail(voicemail)),
    updateHoldMusic: holdMusic => dispatch(updateHoldMusic(holdMusic)),
    updateSettings: settings => dispatch(updateSettings(settings))
})

class GeneralSettings extends PureComponent {
    constructor (props) {
        super(props)
        const settings = JSON.parse(JSON.stringify(this.props.settings || {}))
        this.state = {
            loading: !this.props.settings,
            isHoldMusicLoading: true,
            isVoicemailLoading: true,
            voicemailHasChange: false,
            holdMusicHasChange: false,
            showValidationErrors: false,
            callerId: '',
            holdMusicSaveType: 'choose',
            showCompanyInboxAlert: true,
            showVoicemailAlert: true,
            showVideoMeetingsAlert: true,
            showPrompt: false,
            promptMessage: null,
            ...settings
        }
    }

    componentDidMount () {
        if (!this.props.settings) this.loadCurrentSettings()
    }

    componentDidUpdate () {
        if (!this.state.loading) {
            const changes = this.getChanges()
            if (!this.changes || Boolean(this.changes.length) !== Boolean(changes.length)) {
                this.props.setBusy(Boolean(changes.length))
                this.changes = changes
            }
        }
    }

    loadCurrentSettings = async () => {
        const currentSettings = this.props.settings || {}
        const companySettings = await api.getCompanySettings()
        currentSettings.companyName = companySettings.company
        currentSettings.meetingsDomain = companySettings.meetings_domain
        currentSettings.address = companySettings.address
        currentSettings.holdMusicOptions = companySettings.hold_music_options || []
        currentSettings.callRecordingBeep = companySettings.call_recording_beep
        currentSettings.dialByNameDirectoryType = companySettings.directory_type
        currentSettings.timezone = companySettings.timezone
        currentSettings.callerId = companySettings.caller_id
        this.props.updateSettings(currentSettings)
        this.setState({ loading: false, ...JSON.parse(JSON.stringify(this.props.settings)) })
    }

    getChanges = () => {
        const changes = []
        const currentSettings = this.props.settings || {}
        const currentSelectedHoldMusic = currentSettings.holdMusicOptions && currentSettings.holdMusicOptions.find(hm => hm.selected)
        const selectedHoldMusic = this.state.holdMusicOptions && this.state.holdMusicOptions.find(hm => hm.selected)

        if (this.state.voicemailHasChange) changes.push('voicemail')
        if (this.state.holdMusicHasChange) changes.push('holdMusic')
        if (currentSettings.companyName !== this.state.companyName) changes.push('nameAddress')
        if (currentSettings.meetingsDomain !== this.state.meetingsDomain) changes.push('meetingsDomain')
        if (currentSettings.callRecordingBeep !== this.state.callRecordingBeep) changes.push('callRecordingBeep')
        if (currentSettings.dialByNameDirectoryType !== this.state.dialByNameDirectoryType) changes.push('dialByNameDirectoryType')
        if (currentSettings.timezone !== this.state.timezone) changes.push('timezone')
        if (currentSettings.callerId !== this.state.callerId) changes.push('callerId')
        if (currentSettings.address && this.state.address && (
            Boolean(currentSettings.address) !== Boolean(this.state.address) ||
            currentSettings.address.street !== this.state.address.street ||
            currentSettings.address.city !== this.state.address.city ||
            currentSettings.address.state !== this.state.address.state ||
            currentSettings.address.zip !== this.state.address.zip ||
            currentSettings.address.country !== this.state.address.country
        )) changes.push('nameAddress')
        if (this.state.holdMusicSaveType === 'choose') {
            if (Boolean(currentSelectedHoldMusic) !== Boolean(selectedHoldMusic)) changes.push('holdMusic')
            if (!changes.includes('holdMusic') &&
                currentSelectedHoldMusic &&
                currentSelectedHoldMusic.value !== selectedHoldMusic.value
            ) changes.push('holdMusic')
        }
        return changes
    }

    liveFormatZip = (zip) => {
        this.setState({ zipError: '' })
        let formatedZip = ''
        if (this.state.address.country === 'US') {
            formatedZip = zip.replace(/[^\d]/g, '')
            if (formatedZip.length === 6) return formatedZip.slice(0, -1)
            if (formatedZip.length > 6) return ''
            return formatedZip
        } else {
            zip = zip.replace(/[^a-zA-Z\d\s:]/g, '')
            let isLastNumeric = true
            Array.from(zip).forEach(karakter => {
                if (formatedZip.length === 7) return
                if (isLastNumeric) {
                    if (/^\d+$/.test(karakter)) return
                } else {
                    if (/^[a-zA-Z]+$/.test(karakter)) return
                }
                if (formatedZip.length === 3) formatedZip += ' '
                if (karakter === ' ') return
                formatedZip += karakter.toUpperCase()
                if (formatedZip.length === 3 && this.state.address.zip.length === 2) formatedZip += ' '
                isLastNumeric = !isLastNumeric
            })
            return formatedZip
        }
    }

    onAddressFieldChange = (fieldName, value) => {
        value = fieldName === 'zip' ? this.liveFormatZip(value) : value
        const address = this.state.address
        address[fieldName] = value
        if (fieldName === 'country') {
            address.zip = ''
            address.state = ''
            address.city = ''
            address.street = ''
        }
        this.setState({ address: { ...address } })
    }

    updateVoicemailHasChange = voicemailHasChange => {
        if (this.state.voicemailHasChange === voicemailHasChange) return
        this.setState({ voicemailHasChange })
    }

    onVoicemailLoadingStateChanged = isVoicemailLoading => this.setState({ isVoicemailLoading })

    onHoldMusicChange = holdMusic => {
        const holdMusicOptions = this.state.holdMusicOptions
        holdMusicOptions.forEach(wm => (wm.selected = wm.value === holdMusic.value))
        holdMusic.selected = true
        this.setState({ holdMusic, holdMusicOptions })
    }

    updateHoldMusicHasChange = holdMusicHasChange => {
        if (this.state.holdMusicHasChange === holdMusicHasChange) return
        this.setState({ holdMusicHasChange })
    }

    onHoldMusicLoadingStateChanged = isHoldMusicLoading => this.setState({ isHoldMusicLoading })

    setHoldMusicSaveType = holdMusicSaveType => this.setState({ holdMusicSaveType })

    isPartOfStates = (states, state) => {
        const found = states.some(el => el.value === state)
        return found
    }

    onSaveHoldMusic = async holdMusic => {
        const holdMusicOptions = this.state.holdMusicOptions
        holdMusicOptions.forEach(hm => (hm.selected = false))
        holdMusic.selected = true
        holdMusicOptions.splice(1, 0, holdMusic)
        this.setState({ holdMusicOptions })
        this.setHoldMusicSaveType('choose')
        this.updateHoldMusicHasChange(false)

        this.setState({ loading: true })
        const response = await this.saveCompanySettings()
        this.updateSettings(response)
        this.setState({ loading: false })
    }

    renderCompanyNameSubsection = () => {
        const { classes } = this.props
        const companyName = this.state.companyName || ''
        let error = this.state.showValidationErrors && !companyName ? 'Required field' : ''
        if (!error && this.state.showValidationErrors && this.state.companyError) error = this.state.companyError
        return (
            <div className={classes.subsection}>
                <span className={classes.question}><span className='em'>Company name</span></span>
                <TextField
                    content = {companyName}
                    onInputChange = {companyName => this.setState({ companyName })}
                    className = {{ wrapper: classes.textField }}
                    error = {error}
                />
            </div>
        )
    }

    renderCallerIdSubsection = () => {
        const { classes } = this.props

        const callerId = this.state.callerId || ''
        let error = this.state.showValidationErrors && !callerId ? 'Required field' : ''
        if (!error && this.state.showValidationErrors && this.state.caller_idError) error = this.state.caller_idError
        const onChange = callerId => {
            callerId = callerId.replace(/[^a-zA-Z0-9\s]/g, '')
            if (callerId.length > 15) callerId = callerId.substring(0, 15)
            this.setState({ callerId })
        }

        return (
            <div className={classes.subsection}>
                <span className={classes.question}><span data-user-pilot-id='configure.company.cnam.title' className='em'>Caller ID name</span> What Caller ID name should show when you call out? <RemoteInfoTipMui arrow remoteConfigIDs={['configure_caller_id_disclamer']} placement={RemoteInfoTipMuiPlacement.TOP}/></span>
                <TextField
                    content = {callerId}
                    onInputChange = {onChange}
                    className = {{ wrapper: classes.textField }}
                    error = {error}
                    uppercase
                />
            </div>
        )
    }

    renderAddressSubsection = () => {
        const { classes } = this.props
        const address = this.state.address || { street: '', city: '', state: '', zip: '', country: '' }
        const stateItems = address.country === 'US' ? stateOptions : provinceOptions
        return (
            <div className={classes.subsection}>
                <span className={classes.question}><span className='em'>Business address </span> Be sure to include your suite or apt number</span>
                <div className={classes.businessFieldsWrapper}>
                    <TextField
                        label = 'Street address'
                        content = {address.street}
                        onInputChange = {street => this.onAddressFieldChange('street', street)}
                        error = {this.state.showValidationErrors && !address.street ? 'Required field' : ''}
                        data-test-id = 'street'
                    />
                    <div className='address-row-2'>
                        <TextField
                            label = 'City'
                            content = {address.city}
                            onInputChange = {city => this.onAddressFieldChange('city', city)}
                            error = {this.state.showValidationErrors && !address.city ? 'Required field' : ''}
                            data-test-id = 'city'
                        />
                        <Menu
                            label = {address.country === 'US' ? 'State' : 'Province'}
                            variant = 'single'
                            items = {stateItems}
                            default = {this.isPartOfStates(stateItems, address.state) ? address.state : stateItems[0].value }
                            onChange = {selectedState => this.onAddressFieldChange('state', selectedState.value)}
                            className = {{ wrapper: classes.addressMenu, itemsWrapper: classes.dropdownItemsWrapper, item: classes.menuItem }}
                            controlable
                            data-test-id = 'state'
                        />
                    </div>
                    <div className='address-row-3'>
                        <TextField
                            label = {address.country === 'US' ? 'Zip code' : 'Postal code'}
                            content = {address.zip}
                            onInputChange = {zip => this.onAddressFieldChange('zip', zip)}
                            error = {this.state.showValidationErrors ? this.state.zipError : ''}
                            data-test-id = 'zip'
                        />
                        <Menu
                            label = 'Country'
                            variant = 'single'
                            items = {conutryOptions}
                            default = {address.country}
                            onChange = {selectedState => this.onAddressFieldChange('country', selectedState.value)}
                            className = {{ wrapper: classes.addressMenu, itemsWrapper: classes.dropdownItemsWrapper, item: classes.menuItem }}
                            controlable
                            data-test-id = 'country'
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderTimezoneSubsection = () => {
        const { classes } = this.props
        return (
            <div className={classes.subsection}>
                <span className={classes.question}><span className='em'>Timezone </span> What is the main timezone for your business? <RemoteInfoTipMui arrow remoteConfigIDs={['configure_company_general_settings_timezone']} placement={RemoteInfoTipMuiPlacement.TOP}/></span>
                <TimezoneField
                    savedTimezone = {this.props.settings?.timezone}
                    timezoneValue = {this.state.timezone}
                    className = {{ wrapper: classes.pdcMenu, itemsWrapper: classes.pdcMenuItems }}
                    onChange = {timezone => this.setState({ timezone })}
                    origin = 'general-settings'
                />
            </div>
        )
    }

    renderDialByNameDirectoryTypeSection = () => {
        const { classes } = this.props
        const { dialByNameDirectoryType } = this.state
        return (
            <div className={classes.subsection}>
                <span className={classes.question}>Set <span className='em'>Dial by name directory type</span></span>
                <div className={classes.radioButtonsWrapper}>
                    <div>
                        <SelectionControl
                            variant = 'radio'
                            checked = {dialByNameDirectoryType === 'first_name'}
                            onClick = {() => this.setState({ dialByNameDirectoryType: 'first_name' })}
                            name = 'dial-by-name-directory-type-first-name'
                            value = 'dial-by-name-directory-type-first-name'
                            className = {{ wrapper: classes.radioWrapper }}
                        /><span>First name</span>
                    </div>
                    <div>
                        <SelectionControl
                            variant = 'radio'
                            checked = {dialByNameDirectoryType === 'last_name'}
                            onClick = {() => this.setState({ dialByNameDirectoryType: 'last_name' })}
                            name = 'dial-by-name-directory-type-last-name'
                            value = 'dial-by-name-directory-type-last-name'
                            className = {{ wrapper: classes.radioWrapper }}
                        /><span>Last name</span>
                    </div>
                </div>
            </div>
        )
    }

    renderCompanyInfoSection = () => {
        const { classes } = this.props
        return (
            <div className={classes.section}>
                <div className={classes.header}>Account settings</div>
                <div className={classes.sectionContent}>
                    {!this.props.showOnlyAccountSettings && this.renderCompanyNameSubsection()}
                    {this.renderCallerIdSubsection()}
                    {!this.props.showOnlyAccountSettings && this.renderAddressSubsection()}
                    {this.renderTimezoneSubsection()}
                    {(process.env.REACT_APP_SHOW_DIAL_BY_NAME_TYPE_SETTING) ? this.renderDialByNameDirectoryTypeSection() : null}
                </div>
            </div>
        )
    }

    renderHoldMusicSubsection = () => {
        const { classes } = this.props
        const musicOptions = this.state.holdMusicOptions || []
        if (!musicOptions.length) return null
        const selectedHoldMusic = musicOptions.find(m => m.selected)
        const error = Boolean(this.state.showValidationErrors && (!selectedHoldMusic || this.state.hold_musicError))
        let audio = null
        if (selectedHoldMusic) {
            audio = {
                voipRecording: {
                    voipRecordingId: selectedHoldMusic.value,
                    name: selectedHoldMusic.content
                }
            }
        }

        return (
            <div className={classes.subsection}>
                <span className={classes.question}>
                    <span className='em'>Music on hold </span>
                    <RemoteConfigValue valueId='music_on_hold_sub_text'/>
                </span>
                <AudioManager
                    type = 'hold-music'
                    audio = {audio}
                    customAudioFiles = {musicOptions}
                    onHoldMusicChange = {this.onHoldMusicChange}
                    error = {error}
                    updateAudio = {() => { /**/ }}
                    updateHasChange = {this.updateHoldMusicHasChange}
                    onLoadingStateChanged = {this.onHoldMusicLoadingStateChanged}
                    setHoldMusicSaveType = {this.setHoldMusicSaveType}
                    onSaveHoldMusic = {this.onSaveHoldMusic}
                    save = {this.state.holdMusicSave}
                    ttsLanguage = 'English' // {this.state.voiceLanguage}
                    ttsVoice = 'Joanna' // {this.state.voiceName}
                    hideTtsOptions
                />
            </div>
        )
    }

    renderRecordingBeepSubsection = () => {
        const { classes } = this.props
        return (
            <div className={classes.section}>
                <span className={classes.question}><span className='em'>Recording beep</span> Would you like your callers to hear a beep when calls are recorded?</span>
                <div className={classes.radioButtonsWrapper}>
                    <div>
                        <SelectionControl
                            variant = 'radio'
                            checked = {this.state.callRecordingBeep}
                            onClick = {() => this.setState({ callRecordingBeep: true })}
                            name = 'call-recordings-beep'
                            value = 'call-recordings-beep-on'
                            className = {{ wrapper: classes.radioWrapper }}
                        /><span>Yes</span>
                    </div>
                    <div>
                        <SelectionControl
                            variant = 'radio'
                            checked = {!this.state.callRecordingBeep}
                            onClick = {() => this.setState({ callRecordingBeep: false })}
                            name = 'call-recordings-beep'
                            value = 'call-recordings-beep-off'
                            className = {{ wrapper: classes.radioWrapper }}
                        /><span>No</span>
                    </div>
                </div>
            </div>
        )
    }

    renderCallSettingsSection = () => {
        const { classes } = this.props
        return (
            <div className={classes.section}>
                <div className={classes.header}>Call settings</div>
                <div className={classes.sectionContent}>
                    {this.renderHoldMusicSubsection()}
                    {this.renderRecordingBeepSubsection()}
                </div>
            </div>
        )
    }

    renderVoicemailSubsection = () => {
        const { classes } = this.props
        const alertText = 'The company voicemail is what will play to your users when your team members can\'t pickup.'
        return (
            <div className={classes.subsection}>
                <span className={classes.question} style={{ columnGap: 0 }}>What should be your main&nbsp;<span className='em'>company voicemail message</span>?</span>
                <AudioManager
                    type = 'voicemail'
                    audio = {this.props.voicemail}
                    updateAudio = {this.props.updateVoicemail}
                    updateHasChange = {this.updateVoicemailHasChange}
                    onLoadingStateChanged = {this.onVoicemailLoadingStateChanged}
                    save = {this.state.voicemailSave}
                    ttsLanguage = 'English' // {this.state.voiceLanguage}
                    ttsVoice = 'Joanna' // {this.state.voiceName}
                    extension = {this.props.extension}
                />
                {this.renderAlert(alertText, 'showVoicemailAlert')}
            </div>
        )
    }

    renderAlert = (text, stateName) => {
        const { classes } = this.props
        if (!this.state[stateName]) return null
        const onClose = () => this.setState({ [stateName]: !this.state[stateName] })
        return <div className={classes.alert}>{text}<XIcon className='x-icon' onClick={onClose}/></div>
    }

    renderCompanyInboxSection = () => {
        const { classes } = this.props
        const alertText = 'With Phone.com, you get a shared inbox that all your employees can access and respond from.  We call it the Company Inbox.  It allows you to centrally manage everything that is going on.'
        return (
            <div className={classes.section}>
                <div className={classes.header}>Company inbox</div>
                <div className={classes.sectionContent}>
                    {this.renderAlert(alertText, 'showCompanyInboxAlert')}
                    {this.renderVoicemailSubsection()}
                </div>
            </div>
        )
    }

    renderCancelSettingsSection = () => {
        const { classes } = this.props
        return (
            <div className={classes.section}>
                <div className={classes.header}>Cancel account</div>
                <div className={classes.sectionContent}>
                    <CancelAccount/>
                </div>
            </div>
        )
    }

    renderMeetingsUrlSubsection = () => {
        const { classes } = this.props
        const meetingsDomain = this.state.meetingsDomain || ''
        let error = this.state.showValidationErrors && !meetingsDomain ? 'Required field' : ''
        if (!error && this.state.showValidationErrors && this.state.meetings_domainError) error = this.state.meetings_domainError
        return (
            <div className={classes.subsection}>
                <span className={classes.question}><span className='em'>Meeting URL</span></span>
                <div className={classes.meetingFieldsWrapper}>
                    <TextField
                        content = {meetingsDomain}
                        onInputChange = {meetingsDomain => this.setState({ meetingsDomain: meetingsDomain.replace(/\s/g, '') })}
                        className = {{ wrapper: classes.textField }}
                        error = {error}
                    />
                    <span className='base-url'>.meet.phone.com</span>
                </div>
            </div>
        )
    }

    renderVideoMeetingsSection = () => {
        const { classes } = this.props
        const alertText = 'Phone.com provides you with a secure online video meeting solution, that you can use on any device, and doesn\'t require installing any app, it works right off your browser.  It\'s included for you with all your users.'
        return (
            <div className={classes.section}>
                <div className={classes.header}>Video meetings</div>
                <div className={classes.sectionContent}>
                    {this.renderAlert(alertText, 'showVideoMeetingsAlert')}
                    {this.renderMeetingsUrlSubsection()}
                </div>
            </div>
        )
    }

    isAddressValid = () => {
        const address = this.state.address
        return Boolean(address.street && address.city && address.state && address.zip && address.country)
    }

    validate = async () => {
        if (!this.state.companyName.length) return false
        if (!this.isAddressValid()) return false
        if (!this.state.meetingsDomain) return false
        if (!(this.state.holdMusicOptions.some(hm => hm.selected))) return false
        if (!this.state.callerId) return false
        // if (!this.state.voiceLanguage) return false
        // if (!this.state.voiceName) return false
        return await this.validateZipCode()
    }

    validateZipCode = async () => {
        const address = this.state.address
        const validZipCodeRange = address.country === 'US' ? [5, 5] : [6, 7]
        const errorMessage = address.country === 'US' ? 'Please enter a valid zip code' : 'Please enter a valid postal code'
        if (address.zip.length >= validZipCodeRange[0] && address.zip.length <= validZipCodeRange[1]) {
            const response = await api.isValidZipCode(address.zip)
            if (response.error) {
                this.setState({ zipError: errorMessage })
                return false
            }
            return true
        } else {
            this.setState({ zipError: errorMessage })
            return false
        }
    }

    saveCompanySettings = async () => {
        const changes = this.getChanges()
        const data = {}
        if (changes.includes('nameAddress')) {
            Object.assign(data, {
                company: this.state.companyName,
                address: this.state.address
            })
        }
        if (changes.includes('meetingsDomain')) {
            data.meetings_domain = this.state.meetingsDomain
        }
        if (changes.includes('holdMusic')) {
            if (this.state.holdMusicSaveType === 'choose') {
                const holdMusicOptions = this.state.holdMusicOptions
                const selectedHoldMusic = holdMusicOptions.find(hm => hm.selected)
                let holdMusicValue = selectedHoldMusic.value
                if (holdMusicValue === 'ringing-tone') holdMusicValue = 0
                data.hold_music_id = holdMusicValue
            }
        }
        if (changes.includes('callRecordingBeep')) data.call_recording_beep = this.state.callRecordingBeep
        if (changes.includes('dialByNameDirectoryType')) data.directory_type = this.state.dialByNameDirectoryType
        if (changes.includes('timezone')) data.timezone = this.state.timezone
        if (changes.includes('callerId')) data.caller_id = this.state.callerId.toUpperCase()
        // voice_language:     this.state.voiceLanguage,
        // voice_name:          this.state.voiceName
        if (Object.keys(data).length === 0) return // There is no change that can be handled by set-company-settings
        this.props.updateCompanyName(this.state.companyName)
        return await api.setCompanySettings(data)
    }

    saveVoicemail = () => {
        const changes = this.getChanges()
        if (!changes.includes('voicemail')) return // there is no change in the voicemail
        this.setState({ voicemailSave: true }, () => this.setState({ voicemailSave: false }))
    }

    saveHoldMusic = () => {
        const changes = this.getChanges()
        if (!changes.includes('holdMusic')) return // there is no change in the holdMusic
        this.setState({ holdMusicSave: true }, () => this.setState({ holdMusicSave: false }))
    }

    updateSettings = info => {
        if (!info) return
        let currentSettings = this.props.settings || {}
        if (info.company === 'success') currentSettings.companyName = this.state.companyName
        else if (info.company) currentSettings.companyName = ''

        if (info.company === 'success') currentSettings.address = this.state.address
        else if (info.company) currentSettings.address = ''

        if (info.meetings_domain === 'success') currentSettings.meetingsDomain = this.state.meetingsDomain
        // else if (info.meetings_domain) currentSettings.meetingsDomain = ''

        if (info.hold_music_id === 'success') currentSettings.holdMusicOptions = this.state.holdMusicOptions
        else if (info.hold_music) currentSettings.holdMusicOptions = ''

        if (info.caller_id === 'success') currentSettings.callerId = this.state.callerId
        else if (info.caller_id) currentSettings.callerId = ''

        if (info.call_recording_beep === 'success') currentSettings.callRecordingBeep = this.state.callRecordingBeep

        if (info.timezone === 'success') currentSettings.timezone = this.state.timezone

        if (info.directory_type === 'success') currentSettings.dialByNameDirectoryType = this.state.dialByNameDirectoryType

        currentSettings = JSON.parse(JSON.stringify(currentSettings))
        this.props.updateSettings(currentSettings)
        let hasError = false
        Object.keys(info).forEach(key => {
            if (info[key] !== 'success') {
                const error = info[key][0].toUpperCase() + info[key].substring(1)
                this.setState({ [`${key}Error`]: error })
                hasError = true
            } else this.setState({ [`${key}Error`]: null })
        })
        if (hasError) this.setState({ showValidationErrors: true, showPrompt: true, promptMessage: 'Some changes were not saved because of errors.' })
    }

    onSaveClick = async () => {
        this.setState({ loading: true })
        const isValid = await this.validate()
        if (!isValid) return this.setState({ showValidationErrors: true, loading: false })
        this.setState({ loading: true })
        const response = await Promise.all([
            this.saveCompanySettings(),
            this.saveVoicemail(),
            this.saveHoldMusic()
        ])
        this.updateSettings(response[0])
        this.setState({ loading: false })
    }

    renderFooter = () => {
        const { classes } = this.props
        const changes = this.getChanges()
        return (
            <div className={classes.footer}>
                <Button
                    onClick = {this.onSaveClick}
                    color = 'primary'
                    disabled = {!changes.length}
                >SAVE</Button>
            </div>
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary[0]} size='bigger'/>
            </div>
        )
    }

    renderErrorPrompt = () => {
        return (
            <Prompt
                isOpen = {this.state.showPrompt}
                color = 'attention'
                content = {this.state.promptMessage}
                position = 'bottom'
                onClose = {() => this.setState({ showPrompt: false, promptMessage: null })}
            />
        )
    }

    render = () => {
        const { classes, smallView } = this.props
        const { isVoicemailLoading, isHoldMusicLoading, loading } = this.state
        const isLoading = isVoicemailLoading || isHoldMusicLoading || loading
        const hiddenClass = isLoading ? 'hidden' : ''
        const smallViewClass = smallView ? 'small-view' : ''
        return (
            <div className={`${classes.generalSettings} ${this.props.smallView ? 'small' : ''}`}>
                {isLoading ? this.renderLoader() : null}
                <div className={`${classes.settingWrapper} ${smallViewClass} ${hiddenClass}`}>
                    {this.renderCompanyInfoSection()}
                    {this.renderCallSettingsSection()}
                    {this.renderCompanyInboxSection()}
                    {this.renderVideoMeetingsSection()}
                    {this.renderCancelSettingsSection()}
                </div>
                {!isLoading ? this.renderFooter() : null}
                {this.renderErrorPrompt()}
            </div>
        )
    }
}

GeneralSettings.propTypes = {
    // Material Ui's withStyles' classes
    classes: PropTypes.object,
    setBusy: PropTypes.func,
    smallView: PropTypes.bool,
    settings: PropTypes.object | null,
    updateSettings: PropTypes.func,
    voicemail: PropTypes.object | null,
    updateVoicemail: PropTypes.func,
    extension: PropTypes.object,
    updateCompanyName: PropTypes.func,
    showOnlyAccountSettings: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GeneralSettings))
