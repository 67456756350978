<template>
    <v-simple-table id="stats-table" data-test-id="per-ext-graph-table">
        <thead data-test-id="table-head">
            <tr data-test-id="head-row">
                <th data-test-id="extension">{{ $lang.t('app.extension', 'Extension') }}</th>
                <th v-for="(prop, i) in Object.keys(items)" :data-test-id="i" :key="`th-${i}`">
                    {{ fields[prop].translation }}
                </th>
            </tr>
        </thead>
        <tbody data-test-id="table-body">
            <tr
                v-for="(extension, i) in extensions"
                :data-test-id="`per-ext-graph-item-${i}-row`"
                :key="`per-ext-graph-item-${i}-row`"
            >
                <td
                    :class="{ disabled: extension && typeof extension !== 'object' }"
                    :title="false"
                    data-test-id="extension"
                >
                    {{ extensionName(extension) }}
                </td>
                <td v-for="prop in Object.keys(items)" :data-test-id="prop" :key="prop">
                    <HorizontalBar
                        :color="colors[findExtensionId(extension)]"
                        :item="items[prop].find((x) => x.extension === extension)"
                        :title="title(items[prop].find((x) => x.extension === extension), prop)"
                        :max="Math.max.apply(Math, items[prop].map((x) => x.display_value))"
                        :data-test-id="`per-ext-graph-item-${i}-bar`"
                    />
                </td>
            </tr>
            <tr data-test-id="per-ext-bottom-graph">
                <td data-test-id="empty-cell"><br/></td>
                <td v-for="prop in Object.keys(items)" :data-test-id="prop" :key="`bottmo-${prop}`">
                    <div class="d-flex justify-space-between">
                        <span class="min">
                            {{
                                Math.round(
                                    formatters[fields[prop]['type']](
                                        Math.min.apply(
                                            Math,
                                            items[prop].map((x) => x.value)
                                        )
                                    )
                                )
                            }}
                        </span>
                        {{ $lang.t('app.calls-lowercase', 'calls') }}
                        <span class="max">
                            {{
                                Math.round(
                                    formatters[fields[prop]['type']](
                                        Math.max.apply(
                                            Math,
                                            items[prop].map((x) => x.value)
                                        )
                                    )
                                )
                            }}
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
  import { OrangeColor } from 'orange-colors';
  import { primary_color } from '../../../libs/helpers';
  import HorizontalBar from '../../elements/HorizontalBar.vue';

  export default {
    components: {
      HorizontalBar,
    },
    props: {
        items: {},
        extensions:{},
        logarithmic:{},
        formatters:{},
        fields:{},
    },
    data() {
      return {
        // logarithmic: false,
        loading: false,
        colors: {},
      };
    },
    created() {
        this.color_extensions();
        for (const prop of Object.keys(this.$props.items)) {
            if (this.$props.items[prop].every((x) => !x.value)) delete this.$props.items[prop];
        }
        if (!Object.keys(this.$props.items)) return null;
        return true;
    },
    methods: {
      async color_extensions() {
        for (const extension of this.$props.extensions) {
            this.$data.colors[this.findExtensionId(extension)] = this.colorByExtensionNumber(extension);
        }
      },
      colorByExtensionNumber(n) {
        let number = n;
        if (number && typeof number === 'object') number = `${number.extension}`;
        const c = new OrangeColor(primary_color(this));
        if (Number.parseInt(number)) {
          c.h = (Number.parseInt(number) % 18) * 20;
        } else {
          c.s = 0;
        }
        return c.html_string;
      },
      findExtensionId(extension) {
        if (extension && typeof extension === 'object') return extension.id;
        return extension;
      },
      extensionName(extension) {
            if (extension && typeof extension === 'object') {
                return `${extension.extension}: ${extension.name}`;
            }
            if (extension === this.$lang.t('app.no-extension', 'No extension')) {
                return extension;
            }

            return `${this.$lang.t('app.deleted', 'Deleted')} ${extension}`;
      },
      title(item, prop) {
        return `${this.extensionName(item.extension)}: ${this.formatters[this.fields[prop]['type']](item.value)}`;
      }
    },
  };
</script>
