<template>
  <div class="extension show-page" data-test-id="extensions-show-page">
    <Submenu
      v-if="routes"
      @input="(v) => active_tab = v"
      :prevent_routing="Array.isArray(tabs) && tabs.length > 0"
      :routes="routes"
      :key="`${l.detect()}${$route.name}`"
    />
    <w-loader v-if="extension.loading"/>
    <div v-else>
      <div v-if="$route.name === 'extensions.call-flow' || active_tab === 'extensions.call-flow'" class="w-h6 info--text text--darken-1 mb-3">
        {{title()}}
      </div>
      <PageTitle v-else class="nowrap">
        {{title()}}
      </PageTitle>
      <w-alert
        v-if="extension.alert"
        :message="extension.alert.message"
        :level="extension.alert.level"
        :closable="extension.enable_close_alert"
        @closed="extension.alert = null"
        class="mb-6"
      />
      <ExtensionFragment
        v-if="($route.name === 'extensions.show' || active_tab === 'extensions.show') && extension.item"
        :_extension="extension.item"
        :readonly="readonly"
        @updateExtension="extension.update($event)"
        data-test-id="extensions-show"
      />
      <CallHandlingRules
        v-else-if="($route.name === 'extensions.call-flow' || active_tab === 'extensions.call-flow') && extension.item"
        :_route="extension.item.route"
        :_extension="extension.item"
        :id="extension.item.route ? extension.item.route.id : null"
        :presave_cb="chr_presave_cb"
        :readonly="readonly"
        @click:cancel="$emit('click:cancel')"
        @updated="on_route_update"
        data-test-id="extensions-call-flow"
      />
      <ExtensionType
        v-else-if="($route.name === 'extensions.type' || active_tab === 'extensions.type') && extension.item"
        :_extension="extension.item"
        :readonly="readonly"
        @updated="extension.updateType($event)"
        data-test-id="extensions-type"
      />
    </div>    
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Submenu from '../../elements/Submenu.vue';
  import Extension from '../../../models/Extension';
  import PageTitle from '../../elements/PageTitle.vue';
  import ExtensionFragment from '../../fragments/Extension.vue';
  import ExtensionType from '../../fragments/ExtensionType.vue';
  import CallHandlingRules from '../../elements/routes/CallHandlingRules.vue';

  export default {
    props: {
      id: {
        type: Number
      },
      _extension: {
        type: Object
      },
      tabs: {
        type: Array,
      }
    },
    components: {
      Submenu,
      PageTitle,
      ExtensionType,
      ExtensionFragment,
      CallHandlingRules,
    },
    data() {
      return {
        l,
        routes: {},
        alert: null,
        loading: true,
        readonly: false,
        extension: new Extension(this.$session, vueComponent(this)),
        last_title: '',
        active_tab: null,
      };
    },
    async created() {
      this.$data.routes = this.prepare_routes();
      if (this.$props._extension || this.$route.params._extension) {
        this.$data.extension.item = this.$props._extension || this.$route.params._extension;
      } else {
        await this.extension.getItem(this.$props.id ? this.$props.id : this.$route.params.id);
      }
      this.$data.readonly = this.$data.extension.item.is_nxt;
    },
    methods: {
      prepare_routes() {
        const allowed_routes = {
          'extensions.show': { title: l.t('app.settings', 'Settings')},
          'extensions.visualizer': { title: l.t('call-handling.visualizer', 'Visualizer')},
          'extensions.call-flow': { title: l.t('call-handling.call-handling', 'Call Handling')},
          'extensions.type': { title: l.t('app.type', 'Type')},
        };
        let routes = {};
        if (Array.isArray(this.$props.tabs) && this.$props.tabs.length) {
          for (const tab of this.$props.tabs) {
            if (allowed_routes[tab]) routes[tab] = allowed_routes[tab];
          }
        } else {
          routes = allowed_routes;
        }

        return routes;
      },
      async on_route_update(data) {
        this.$data.extension.loading = true;
        try {
          if (!this.$data.extension.item.route || (this.$data.extension.item.route && this.$data.extension.item.route.id !== data.id)) {
            this.$data.extension.item.route = await this.$data.extension.update_route(data);
          }
          this.$global_emitter.$emit('item_updated')
          this.$data.extension.successfulUpdate();
        } catch (err) {
          this.$data.extension.validation_error(err);
        }
        this.$data.extension.loading = false;
      },
      async chr_presave_cb() {
          if (this.$data.extension.item && this.$data.extension.item.device_membership && !this.$data.extension.item.bypass_hard_phone) {
            const updated_extension = await this.$session.patch_item(
              `/extensions/${this.$data.extension.item.id}`,
              { bypass_hard_phone: true }
            );
            this.$data.extension.item = updated_extension;
          }
      },
      title() {
        let title = '';
        if (this.$data.extension.item) {
          title += l.t('app.extension', 'Extension');
          title += ` #${this.$data.extension.item.extension} ${this.$data.extension.item.name ? this.$data.extension.item.name : ''}`;
          this.$data.last_title = title;
        } else {
          title = this.$data.last_title;
        }
        return title;
      }
    },
  };
</script>
