import React from 'react'
import { getCurrentNumberRule } from './current_rules'
import RemoteConfigValue from 'remote-config-value'

const MinTextareaHeight = 100
const MaxTextAreaHeight = 250

/**
 * @param phoneNumber
 */
export const areRulesUnsupported = (phoneNumber: any): boolean => {
    // Check for complex menu setup from CP
    return phoneNumber.type === 'Advanced rules'
}

/**
 * @param {string} value
 */
export const computeTextareaHeight = (value: string): string => {
    let height = `${MinTextareaHeight}px`
    const tmpSpan = document.createElement('span')
    tmpSpan.innerText = `${value}.`
    document.getElementsByTagName('body')[0].appendChild(tmpSpan)
    const contentHeight = tmpSpan.offsetHeight
    tmpSpan.remove()
    const totalHeight = contentHeight + 2 * 10 + 2 * 1 + 5 // 10 is padding, 1 is border

    // The '+ 2' is because of 1px border top and 1px border bottom
    if (totalHeight >= MinTextareaHeight && totalHeight <= MaxTextAreaHeight) height = `${totalHeight}px`
    else if (totalHeight < MinTextareaHeight) height = `${MinTextareaHeight}px`
    else if (totalHeight > MaxTextAreaHeight) height = `${MaxTextAreaHeight}px`
    return height
}

/**
 * Does not check nested parameters. Checks only first level
 *
 * @param {Array} array1
 * @param {Array} array2
 * @returns {boolean}
 */
export const areArraysSame = (array1: any[], array2: any[]): boolean => {
    if (array1 === array2) return true
    if (array1.length !== array2.length) return false
    const differentElements = array1.filter(element => !array2.includes(element))
    return !differentElements.length
}

/***/
export enum NumberTypeOption {
    MainCompanyNumber = 'main-company-number',
    UserNumber = 'user-number',
    FaxLine = 'fax-line',
    Other = 'other'
}

/***/
export const NumberTypes: any[] = [
    {
        content: <RemoteConfigValue valueId='configure_number_main_company_type_label'/>,
        value: NumberTypeOption.MainCompanyNumber,
        'data-test-id': 'main-company-number-option'
    },
    // {
    //     content: <RemoteConfigValue valueId='configure_number_user_direct_type_label'/>,
    //     value: NumberTypeOption.UserNumber
    // },
    {
        content: <RemoteConfigValue valueId='configure_number_other_type_label'/>,
        value: NumberTypeOption.Other,
        'data-test-id': 'voice-line-number-option'
    },
    {
        content: <RemoteConfigValue valueId='configure_number_fax_type_label'/>,
        value: NumberTypeOption.FaxLine,
        'data-test-id': 'fax-line-number-option'
    }
]

/***/
export enum OnCallOption {
    Forward = 'ring-users',
    VirtualAnswer = 'use-intelligent-answer-bot',
    LiveAnswer = 'use-live-receptionist',
    LiveAnswerPlus = 'use-live-receptionist-plus',
    Menu = 'use-a-menu',
    Voicemail = 'send-to-voicemail',
    ForwardExternal = 'forward-to-a-number'
}

/***/
export enum OnNothingSelected {
    Forward = 'ring-users',
    Voicemail = 'send-to-voicemail',
    Disconnect = 'disconnect-call'
}

/***/
export enum OnNoAnswer {
    Forward = 'ring-users',
    Voicemail = 'send-to-voicemail'
}

/***/
export const OnNothingSelectedRequestMap = {
    [OnNothingSelected.Forward]: 'forward',
    [OnNothingSelected.Voicemail]: 'voicemail',
    [OnNothingSelected.Disconnect]: 'disconnect'
}

/***/
export enum ScheduleOption {
    OpenHours = 'open-hours',
    ClosedHours = 'after-hours',
    Holidays = 'holidays',
    LunchBreak = 'lunch-break'
}

/***/
export enum CallUsersType {
    Parallel = 'parallel',
    Sequential = 'sequential'
}

/***/
export const hubspotFormConfig = {
    createNew: {
        portalId: '6860053',
        formId: 'fef49b4f-552d-4743-be09-d300496ce6da',
        sfdcCampaignId: '7011K0000014F5mQAE'
    },
    saveScriptCancel: {
        portalId: '6860053',
        formId: '66f85af8-c2e5-4229-9e3d-37f15107ae26',
        sfdcCampaignId: '7011K0000014F5mQAE'
    },
    saveScriptSave: {
        portalId: '6860053',
        formId: '5b7eed00-05a3-4cce-ab88-15edcc36b7d6',
        sfdcCampaignId: '7011K0000014F5mQAE'
    },
    saveScriptHelp: {
        portalId: '6860053',
        formId: '38c92250-47e6-4ba8-a35a-f974f30ed72e',
        sfdcCampaignId: '7011K0000014F5mQAE'
    }
}

/**
 * Checks if there is a change made by the user
 *
 * @param {object} configureNumber - instance of ConfigureNumber
 */
export const checkHasChange = (configureNumber): boolean => {
    const checkCallUserChange = (users, storredUsers): boolean => {
        if (users.length !== storredUsers.length) return true
        return users.some((cu1, i): boolean => storredUsers[i] !== cu1)
    }

    const checkVoicemailChange = (storredRulesOnCallOptionGroup, onCallOptionGroup): boolean => {
        if (storredRulesOnCallOptionGroup.assignVoicemailUser !== onCallOptionGroup.assignVoicemailUser) return true
        return false
    }

    const checkRingUsersChange = (storredRulesOnCallOptionGroup, onCallOptionGroup): boolean => {
        if (checkCallUserChange(onCallOptionGroup.forward[0].users, storredRulesOnCallOptionGroup.forward[0].users)) return true
        if (onCallOptionGroup.forward[1].enabled !== storredRulesOnCallOptionGroup.forward[1].enabled) return true
        if (onCallOptionGroup.forward[1].enabled) {
            if (checkCallUserChange(onCallOptionGroup.forward[1].users, storredRulesOnCallOptionGroup.forward[1].users)) return true
        }
        if (checkVoicemailChange(storredRulesOnCallOptionGroup, onCallOptionGroup)) return true
        return false
    }

    const checkPlayMenuChange = (storredRulesOnCallOptionGroup, onCallOptionGroup): boolean => {
        // Check greeting change
        const menuGreeting = onCallOptionGroup.menu.greeting
        const storredMenuGreeting = storredRulesOnCallOptionGroup.menu.greeting
        if (!menuGreeting.isEqualTo(storredMenuGreeting)) return true
        // Check menu actions change
        const menuActions = onCallOptionGroup.menu.menuActions
        if (Object.keys(menuActions).length === 0) return true
        const menuOptions = Object.keys(menuActions)
        const storredMenuOptions = Object.keys(storredRulesOnCallOptionGroup.menu.menuActions)
        if (storredMenuOptions.length !== menuOptions.length) return true
        let hasMenuOptionChange = false
        menuOptions.forEach((mo): void => {
            if (hasMenuOptionChange) return
            if (!storredMenuOptions.includes(mo)) hasMenuOptionChange = true
            const menuAction = menuActions[mo]
            const storredRuleMenuAction = storredRulesOnCallOptionGroup.menu.menuActions[mo]
            if (menuAction !== storredRuleMenuAction) hasMenuOptionChange = true
        })
        if (hasMenuOptionChange) return true
        // Check on nothing selected change
        const userNothingSelectedOption = onCallOptionGroup.menu.onNothingSelected.option
        if (userNothingSelectedOption !== storredRulesOnCallOptionGroup.menu.onNothingSelected.option) return true
        if (userNothingSelectedOption === OnNothingSelected.Forward) {
            if (onCallOptionGroup.menu.onNothingSelected.ringUsers.length !== storredRulesOnCallOptionGroup.menu.onNothingSelected.ringUsers.length) return true
            let hasUserNothingSelectedRingUsersChange = false
            onCallOptionGroup.menu.onNothingSelected.ringUsers.forEach((u): void => {
                if (hasUserNothingSelectedRingUsersChange) return
                if (!storredRulesOnCallOptionGroup.menu.onNothingSelected.ringUsers.includes(u)) hasUserNothingSelectedRingUsersChange = true
            })
            if (hasUserNothingSelectedRingUsersChange) return true
        } else if (userNothingSelectedOption === OnNothingSelected.Voicemail) {
            if (onCallOptionGroup.menu.onNothingSelected.voicemailUser !== storredRulesOnCallOptionGroup.menu.onNothingSelected.voicemailUser) return true
        }
        return false
    }

    const checkForwardExternalChange = (storredRulesOnCallOptionGroup, onCallOptionGroup): boolean => {
        if (storredRulesOnCallOptionGroup.forwardExternal.phoneNumber !== onCallOptionGroup.forwardExternal.phoneNumber) return true
        if (storredRulesOnCallOptionGroup.forwardExternal.callerId !== onCallOptionGroup.forwardExternal.callerId) return true
        if (storredRulesOnCallOptionGroup.forwardExternal.voiceTag !== onCallOptionGroup.forwardExternal.voiceTag) return true
        if (storredRulesOnCallOptionGroup.forwardExternal.screening !== onCallOptionGroup.forwardExternal.screening) return true
        if (onCallOptionGroup.forward[1].enabled !== storredRulesOnCallOptionGroup.forward[1].enabled) return true
        if (onCallOptionGroup.forward[1].enabled) {
            if (checkCallUserChange(onCallOptionGroup.forward[1].users, storredRulesOnCallOptionGroup.forward[1].users)) return true
        }
        if (checkVoicemailChange(storredRulesOnCallOptionGroup, onCallOptionGroup)) return true
        return false
    }

    const checkLiveAnswerChange = (storredRules, state, storredRulesOnCallOptionGroup, onCallOptionGroup): boolean => {
        if (storredRules.scriptId !== state.scriptId) return true
        if (onCallOptionGroup.forward[1].enabled !== storredRulesOnCallOptionGroup.forward[1].enabled) return true
        if (onCallOptionGroup.forward[1].enabled) {
            if (checkCallUserChange(onCallOptionGroup.forward[1].users, storredRulesOnCallOptionGroup.forward[1].users)) return true
        }
        if (checkVoicemailChange(storredRulesOnCallOptionGroup, onCallOptionGroup)) return true
        return false
    }

    const checkVirtualAnswerChange = (storredRules, state, storredRulesOnCallOptionGroup, onCallOptionGroup): boolean => {
        if (storredRules.scriptId !== state.scriptId) return true
        if (onCallOptionGroup.forward[1].enabled !== storredRulesOnCallOptionGroup.forward[1].enabled) return true
        if (onCallOptionGroup.forward[1].enabled) {
            if (checkCallUserChange(onCallOptionGroup.forward[1].users, storredRulesOnCallOptionGroup.forward[1].users)) return true
        }
        if (checkVoicemailChange(storredRulesOnCallOptionGroup, onCallOptionGroup)) return true
        return false
    }

    const props = configureNumber.props
    const state = configureNumber.state
    const storredRules = getCurrentNumberRule(props)

    if (state.smsForwardUser !== storredRules.smsForwardUser) return true
    if (state.numberTypeOption !== storredRules.numberTypeOption) return true
    let hasChange = false
    if ([NumberTypeOption.MainCompanyNumber, NumberTypeOption.Other].includes(state.numberTypeOption)) {
        const onCallOptionGroups = { ...state.onCallOptionGroups }
        const schedules = props.schedules
        Object.keys(onCallOptionGroups).forEach((onCallOptionGroupName): boolean => {
            if (!schedules?.length && onCallOptionGroupName !== ScheduleOption.OpenHours) return
            if (!schedules?.find((s): boolean => s.name === ScheduleOption.LunchBreak) && onCallOptionGroupName === ScheduleOption.LunchBreak) return
            if (!schedules?.find((s): boolean => s.name === ScheduleOption.Holidays) && onCallOptionGroupName === ScheduleOption.Holidays) return
            if (hasChange) return
            const onCallOptionGroup = onCallOptionGroups[onCallOptionGroupName]
            const storredOnCallOptionGroup = storredRules.onCallOptionGroups[onCallOptionGroupName]
            if (onCallOptionGroup.sameAsClosedHours !== storredOnCallOptionGroup.sameAsClosedHours) return (hasChange = true)
            if (onCallOptionGroup.sameAsClosedHours) return
            if (onCallOptionGroup.onCallOption !== storredOnCallOptionGroup.onCallOption) return (hasChange = true)
            if (onCallOptionGroup.hasGreeting !== storredOnCallOptionGroup.hasGreeting) return (hasChange = true)
            if (!onCallOptionGroup.greeting.isEqualTo(storredOnCallOptionGroup.greeting)) return (hasChange = true)

            const checkMethods = {
                [OnCallOption.Forward]: checkRingUsersChange,
                [OnCallOption.Menu]: checkPlayMenuChange,
                [OnCallOption.Voicemail]: checkVoicemailChange,
                [OnCallOption.ForwardExternal]: checkForwardExternalChange,
                [OnCallOption.LiveAnswer]: checkLiveAnswerChange,
                [OnCallOption.VirtualAnswer]: checkVirtualAnswerChange,
                [OnCallOption.LiveAnswerPlus]: checkLiveAnswerChange,
                '': (): boolean => false
            }
            if (onCallOptionGroup.onCallOption === OnCallOption.LiveAnswer || onCallOptionGroup.onCallOption === OnCallOption.VirtualAnswer || onCallOptionGroup.onCallOption === OnCallOption.LiveAnswerPlus) {
                if (checkMethods[onCallOptionGroup.onCallOption](storredRules, state, storredOnCallOptionGroup, onCallOptionGroup)) return (hasChange = true)
            } else {
                if (checkMethods[onCallOptionGroup.onCallOption](storredOnCallOptionGroup, onCallOptionGroup)) return (hasChange = true)
            }
        })
        if (!hasChange && storredRules.useSchedules !== state.useSchedules) hasChange = true
    }

    return hasChange
}

/**
 * Check if there is a live receptionist in the schedules
 *
 * @param configureCallingInstance
 * @returns {boolean}
 */
export const liveReceptionistPresent = (configureCallingInstance): boolean => {
    const activeSchedules = getActiveSchedules(configureCallingInstance.props.schedules)
    const onCallOptionGroups = configureCallingInstance.state.onCallOptionGroups
    let hasLiveReceptionist = false

    activeSchedules.forEach((scheduleOption) => {
        if (onCallOptionGroups[scheduleOption].onCallOption === OnCallOption.LiveAnswer || onCallOptionGroups[scheduleOption].onCallOption === OnCallOption.VirtualAnswer || onCallOptionGroups[scheduleOption].onCallOption === OnCallOption.LiveAnswerPlus) {
            hasLiveReceptionist = true
        }
    })

    return hasLiveReceptionist
}

/**
 * Check if there is an AI-Connect in the schedules
 *
 * @param configureCallingInstance
 * @returns {boolean}
 */
export const intelligentAnswerBotPresent = (configureCallingInstance): boolean => {
    const activeSchedules = getActiveSchedules(configureCallingInstance.props.schedules)
    const onCallOptionGroups = configureCallingInstance.state.onCallOptionGroups
    let hasAIConnect = false

    activeSchedules.forEach((scheduleOption) => {
        if (onCallOptionGroups[scheduleOption].onCallOption === OnCallOption.VirtualAnswer) {
            hasAIConnect = true
        }
    })

    return hasAIConnect
}

/**
 * @param {object} phoneNumber
 * @returns {number} script id
 */
export const getScriptId = (phoneNumber): number => {
    let scriptId = null
    if (phoneNumber.call_rules && phoneNumber.call_rules.rule_groups) {
        phoneNumber.call_rules.rule_groups.forEach((ruleGroup) => {
            if (ruleGroup.rules && ruleGroup.rules.actions) {
                ruleGroup.rules.actions.forEach((action) => {
                    if (action.type === 'live_answer') {
                        scriptId = action.data.id
                    }
                })
            }
        })
    }
    return scriptId
}

/**
 * @param {number} script_id
 * @param {Array<object>} numbers
 * @returns {boolean} true if script is present on other numbers
 */
export const isScriptPresentOnOtherNumbers = (scriptId: number, phoneNumber, numbers): boolean => {
    // return true if action.type == 'live_answer' && action.data.id == script_id and schedule.status == 'on'
    let isPresent = false
    // check if script is present on any other numbers
    numbers.forEach((number) => {
        if (number.call_rules && number.call_rules.rule_groups && number.phone_number !== phoneNumber.phone_number) {
            number.call_rules.rule_groups.forEach((ruleGroup) => {
                if (ruleGroup.rules && ruleGroup.rules.actions) {
                    ruleGroup.rules.actions.forEach((action) => {
                        if (action.type === 'live_answer' && ruleGroup.schedule) {
                            if (action.data && action.data.id === scriptId && ruleGroup.schedule.status === 'on') {
                                isPresent = true
                            }
                        }
                    })
                }
            })
        }
    })

    return isPresent
}

/**
 * @param {Array<object>} schedules
 * @returns {Array<string>} active schedules
 */
const getActiveSchedules = (schedules): any[] => {
    const scheduleOptions = Object.values(ScheduleOption)
    const activeSchedules = schedules.filter(s => s.status === 'on')
    const finalActiveSchedules = []
    scheduleOptions.forEach(o => {
        if (!activeSchedules.find(s => s.name === o) && (o !== ScheduleOption.ClosedHours)) return null
        finalActiveSchedules.push(o)
    })
    return finalActiveSchedules
}

/**
 * @param {object} onCallOptionGroups
 * @param {string} selectedScheduleOption
 * @param {Array<object>} schedules
 * @returns {boolean} true if script is present on any other schedule for a phone number
 */
export const isScriptPresentOnOtherSchedule = (onCallOptionGroups, selectedScheduleOption, schedules): boolean => {
    const activeSchedules = getActiveSchedules(schedules)
    let isPresent = false
    activeSchedules.forEach((scheduleOption) => {
        if (scheduleOption !== selectedScheduleOption) {
            if (onCallOptionGroups[scheduleOption] && onCallOptionGroups[scheduleOption].onCallOption === onCallOptionGroups[selectedScheduleOption].onCallOption) {
                isPresent = true
            }
        }
    })
    return isPresent
}

/**
 * @param {Array<number>} scriptIds
 * @param {Array<object>} existingReceptionistScripts
 * @returns {boolean} true if script is present
 */
export const isScriptOfTypePresent = (scriptIds: Array<number>, existingReceptionistScripts): boolean => {
    return existingReceptionistScripts.some((script) => script.id in scriptIds)
}
