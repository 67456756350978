import React, { useState, useCallback, useEffect } from 'react'
import { TextField } from 'text-field-mui'
import { InputAdornment } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles } from '@material-ui/core'
import api from '../../util/api_v5.js'
import { formatPhoneNumber } from 'phone-numbers'
import { debounce } from 'debounce'

const useStyles = makeStyles(() =>
    createStyles({
        phoneNumberNameField: ({ isMobileView }: { isMobileView: boolean }) => ({
            cursor: 'text',
            minWidth: 160,
            width: isMobileView ? 160 : undefined
        }),
        loadingInputIcon: {
            marginRight: 14,
            padding: 3
        }
    })
)

interface PhoneNumber {
    id: string;
    phone_number: string; // eslint-disable-line @typescript-eslint/naming-convention
    name: string;
}

interface PhoneNumberNameProps {
    phoneNumber: PhoneNumber;
    updatePhoneNumber: (updatedNumber: PhoneNumber) => void;
    isMobileView?: boolean;
}

/**
 * Displays a phone number's name / number, allows the user to edit the name.
 */
const PhoneNumberName = ({ phoneNumber, updatePhoneNumber, isMobileView }: PhoneNumberNameProps): JSX.Element => {
    const classes = useStyles({ isMobileView })

    const [name, setName] = useState<string>(phoneNumber.name)
    const [saving, setSaving] = useState<boolean>(false)

    const saveName = async (newName: string) => {
        setSaving(true)
        try {
            await api.updatePhoneNumber({ id: phoneNumber.id, name: newName })
            updatePhoneNumber({ ...phoneNumber, name: newName })
            setName(newName)
        } catch (error) {
            console.error(`Failed to save name: ${newName} for phone number with id: ${phoneNumber.id}`)
        }
        setSaving(false)
    }

    const autoSaveName = useCallback(debounce(async (newName: string) => {
        await saveName(newName)
    }, 1000), [])

    useEffect(() => {
        if (phoneNumber.name !== name && !saving) {
            autoSaveName(name)
        }
    }, [phoneNumber.name, name, saving])

    const onKeyDown = e => {
        if (e.key === 'Enter' && !saving) {
            saveName(name)
        }
    }

    return (
        <div className={classes.phoneNumberNameField}>
            <TextField
                label = 'Name'
                value = {name}
                onChange = {e => setName(e.target.value.slice(0, 30))}
                onXClick = {() => saveName(formatPhoneNumber(phoneNumber.phone_number))}
                disabled = {saving}
                onKeyDown = {onKeyDown}
                InputProps = {saving
                    ? {
                        endAdornment: (
                            <InputAdornment
                                position='end'
                                classes={{ root: classes.loadingInputIcon }}
                            >
                                <CircularProgress size='1em'/>
                            </InputAdornment>
                        )
                    }
                    : null}
                noLabelOnActiveOrResolved
            />
        </div>
    )
}

export default PhoneNumberName
