import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import api from '../../util/api_v5.js'
import Api from 'api'
import { addPhoneNumbers, deletePhoneNumber, resetPhoneNumbers, updatePhoneNumber } from '../../actions/phone-numbers'
import { formatPhoneNumber } from 'phone-numbers'
import { getFeatureEnabled } from 'feature-flag'
import LoaderFull from 'loader-full'
import EnableCallRecordingModal from './EnableCallRecordingModal'
import EditCallerIdModal from './EditCallerIdModal'
import ConfirmDeleteModal from 'confirm-delete-modal'
import ConfirmModal from 'confirm-modal'
import Prompt from 'pdc-prompt'
import styles from './myNumbersStyles'
import { withStyles } from '@material-ui/core'
import { theme } from 'get-theme'
import gtmDataPush from 'gtm-events'
import MyNumbersLarge from './MyNumbersLarge'
import MyNumbersSmall from './MyNumbersSmall'
import ConfigureCalling, { getScriptId, isScriptPresentOnOtherNumbers } from 'configure-calling'
import { Switch, Route } from 'react-router-dom'
import Spinner from 'spinner'
import { getValue } from 'remote-config-value'

const GTM_APP_NAME = 'my-numbers'
const GTM_MAP = { RECORD: 1, DONT_RECORD: 0 }

const mapStateToProps = state => ({
    phoneNumbers: state.phoneNumbers
})

const mapDispatchToProps = dispatch => ({
    addPhoneNumbers: phoneNumbers => dispatch(addPhoneNumbers(phoneNumbers)),
    deletePhoneNumber: phoneNumber => dispatch(deletePhoneNumber(phoneNumber)),
    resetPhoneNumbers: () => dispatch(resetPhoneNumbers()),
    updatePhoneNumber: phoneNumber => dispatch(updatePhoneNumber(phoneNumber))
})

class MyNumbers extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loadingPhoneNumbers: this.props.phoneNumbers.notLoaded,
            loadingUsers: !this.props.users || !this.props.users.length,
            screen: 'numbers',
            nameEdit: null,
            showUnassignNumDialog: false,
            showDeleteNumDialog: false,
            pendingUnassignNum: false,
            pdcPromptColor: 'secondary',
            pdcPromptOpen: false,
            pdcPromptMessage: null,
            hasMore: true,
            schedules: null,
            editCallerIdNumberId: null,
            defaultCompanyCallerId: null,
            companyExtensionRules: null,
            mainCompanyNumberVoipDidId: null,
            processingAction: false,
            hideCallRecording: false
        }
        this.companyExtension = this.props.extensions?.find(e => e.is_company) || null
    }

    componentDidMount = async () => {
        this._mounted = true
        this.getCompanySchedules()
        const hideCallRecording = await getFeatureEnabled('configure.hide_call_recording')

        if (hideCallRecording) {
            this.setState({
                hideCallRecording: true
            })
        }
        await Promise.all([this.getPhoneNumbers(), this.getCompanyExtensionRules()])

        // TODO: Temporarily it will reload everytime it gets here but once the websockets are done we should not reload on every mount
        // if (this.props.phoneNumbers) return
        // this.props.resetPhoneNumbers()

        if (this._mounted) this.setState({ loadingPhoneNumbers: false })

        if (this.props.newPurchase) {
            this.setState({
                pdcPromptOpen: true,
                pdcPromptMessage: (
                    <div>
                        <div>The numbers you just ordered have been added to your account!</div>
                        <div>Please make sure to assign or configure them via the Edit pen icon.</div>
                    </div>
                )
            })
        }

        const companySettings = await api.getCompanySettings()
        const defaultCompanyCallerId = companySettings?.caller_id || ''
        this.setState({ defaultCompanyCallerId })
    }

    getCompanySchedules = async () => {
        const schedules = await api.loadSchedules()
        this.setState({ schedules: schedules.items })
    }

    getPhoneNumbers = async () => {
        // TODO: Temporarily it will reload everytime it gets here but once the websockets are done we should not reload on every mount
        // if (this.props.phoneNumbers) return
        this.props.resetPhoneNumbers()
        this.setState({ loadingPhoneNumbers: true })
        const getNumbersList = [Api.getMainCompanyNumberVoipDidId(), Api.loadPhoneNumbers(null, { 'include-lrdn': false })]
        const pathConfigureNumber = this.getPathConfigureNumber()
        if (pathConfigureNumber) getNumbersList.push(Api.loadPhoneNumbers(null, { did: pathConfigureNumber }, 1))
        const response = await Promise.all(getNumbersList)
        response.forEach((res, i) => {
            if (i === 0) return this.setState({ mainCompanyNumberVoipDidId: res })
            if (i === 2) res.notInOrder = true
            this.props.addPhoneNumbers(res)
        })
        if (pathConfigureNumber) {
            const pathConfigureNumberObject = this.props.phoneNumbers.items.find(pn => pn.phone_number === pathConfigureNumber)
            if (pathConfigureNumberObject) this.setState({ configureNumber: pathConfigureNumberObject })
            else this.updateRouterHistory('')
        }
    }

    getPathConfigureNumber = () => {
        const basePath = this.getBasePath()
        const pathname = this.getPathname()
        let afterBasePath = pathname.split(basePath)[1]
        if (afterBasePath) afterBasePath = afterBasePath.substring(1)
        return afterBasePath
    }

    getCompanyExtensionRules = async () => {
        if (!this.companyExtension?.extension_id) return
        const rules = await Api.getExtensionRules(this.companyExtension.extension_id)
        this.setState({ companyExtensionRules: rules })
    }

    componentWillUnmount = () => {
        this._mounted = false
    }

    componentDidUpdate = prevProps => {
        if (!prevProps.users.length && this.props.users.length) this.setState({ loadingUsers: false })
    }

    loadMore = async () => {
        const phoneNumberItems = [...this.props.phoneNumbers.items]
        phoneNumberItems.reverse()
        const cursor = phoneNumberItems.find(pn => !pn.notInOrder).cursor
        const newPhoneNumbers = await api.loadPhoneNumbers(cursor)
        if (newPhoneNumbers.items.length < 20) this.setState({ hasMore: false })
        this.props.addPhoneNumbers(newPhoneNumbers)
    }

    handlepdcPromptClose = () => this.setState({ pdcPromptOpen: false, pdcPromptMessage: null })

    onConfigureNumberClick = phoneNumber => {
        this.updateRouterHistory(phoneNumber.phone_number)
        this.setState({ screen: 'configure', configureNumber: phoneNumber })
    }

    getBasePath = () => this.props.routeProps.match.path.replace(/\/+$/g, '')
    getPathname = () => this.props.routeProps.location.pathname.replace(/\/+$/g, '')

    updateRouterHistory = tabName => {
        const basePath = this.getBasePath()
        this.props.routeProps.history.push(`${basePath}/${tabName}`)
    }

    closeConfigureNumber = () => {
        this.updateRouterHistory('')
        this.setState({ screen: 'numbers', configureNumber: null })
    }

    changeValueInNumber = (number, key, value) => {
        const numbers = this.props.phoneNumbers.items
        // eslint-disable-next-line
        for (const i in numbers) {
            if (numbers[i].id === number.id) {
                numbers[i][key] = value
                this.props.updatePhoneNumber(numbers[i])
                break
            }
        }
    }

    unassignNumber = async (voipPhoneIds) => {
        const phoneNumber = this.state.unassignNumber
        const phoneNumberDeepCopy = JSON.parse(JSON.stringify(phoneNumber))
        if (!phoneNumber) return
        const scriptId = getScriptId(phoneNumberDeepCopy)
        this.setState({ pendingUnassignNum: true, showUnassignNumDialog: false })
        const response = await api.unassignNumber(phoneNumber.voip_did_id, voipPhoneIds)
        if (response.error) {
            const pdcPromptMessage = response.error.voip_did_id === 'inactive' ? getValue('configure_number_inactive_response') : 'Something went wrong while unassigning number.'
            this.setState({ pendingUnassignNum: false, unassignNumber: null, showUnassignNumDialog: false, pdcPromptOpen: true, pdcPromptMessage })
            return false
        }
        // if phone number had a script present on it that isn't present on any other numbers, deactivate it
        if (scriptId && !isScriptPresentOnOtherNumbers(scriptId, phoneNumber, this.props.phoneNumbers.items)) {
            await Api.deactivateLiveAnswerScript(scriptId)
        }

        phoneNumber.call_rules.rule_groups = []
        phoneNumber.sms_forwarding = {}
        phoneNumber.name = 'Unassigned'
        if (phoneNumber.type === 'Main company number') this.setState({ mainCompanyNumberVoipDidId: null })
        delete phoneNumber.type
        api.updatePhoneNumber({ id: phoneNumber.id, name: phoneNumber.name })
        this.props.updatePhoneNumber(phoneNumber)
        this.setState({ pendingUnassignNum: false, unassignNumber: null, showUnassignNumDialog: false, pdcPromptOpen: true, pdcPromptMessage: 'Phone number unassigned.' })
    }

    onToggleCallRecordingClick = phoneNumber => {
        if (phoneNumber.record_calls) return this.toggleCallRecording(phoneNumber.voip_did_id, false)
        if (window.hasProUser) this.toggleCallRecording(phoneNumber.voip_did_id, true)
        else this.setState({ callRecordingNumberVoipDidId: phoneNumber.voip_did_id })
    }

    toggleCallRecording = async (phoneNumberVoipDidId, enable) => {
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'record-calls-switch',
            PDC_Value: enable ? GTM_MAP.RECORD : GTM_MAP.DONT_RECORD
        })
        const phoneNumberItems = this.props.phoneNumbers.items
        const phoneNumber = { ...(phoneNumberItems.find(pn => pn.voip_did_id === phoneNumberVoipDidId) || {}) }
        const data = { voip_did_id: phoneNumberVoipDidId, enable }
        this.setState({ loadingPhoneNumbers: true })
        this.onCloseToggleCallRecordingModal()
        await api.toggleCallRecording(data)
        this.setState({ loadingPhoneNumbers: false })
        phoneNumber.record_calls = enable
        this.props.updatePhoneNumber(phoneNumber)
    }

    onCloseToggleCallRecordingModal = () => {
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'record-calls-cancel'
        })
        this.setState({ callRecordingNumberVoipDidId: null })
    }

    getDropdownItems = phoneNumber => {
        const options = []

        if (this.props.screenViewType.isMobileView) options.push({ text: 'Configure', onClick: () => this.onConfigureNumberClick(phoneNumber) })

        if (phoneNumber.type) {
            options.push({ text: 'Unassign', onClick: () => this.setState({ unassignNumber: phoneNumber, showUnassignNumDialog: true }) })
        }

        options.push({ text: 'Edit Caller ID Name', onClick: () => this.onEditCallerIdClick(phoneNumber) })
        options.push({ text: 'Delete Number', onClick: () => this.onDeletePhoneNumberClick(phoneNumber), className: 'danger strong' })

        return options
        /* return [
            {text: 'Configure', onClick: () => this.onConfigureNumberClick(phoneNumber)},
            ...unassignOption,
            {type: 'separator'},
        ] */
    }

    onEditCallerIdClick = phoneNumber => this.setState({ editCallerIdNumberId: phoneNumber.id })
    onDeletePhoneNumberClick = phoneNumber => this.setState({ deletePhoneNumberId: phoneNumber.id })
    onCloseDeletePhoneNumberModal = () => this.setState({ deletePhoneNumberId: null })
    onCloseEditCallerIdModal = () => this.setState({ editCallerIdNumberId: null })

    deletePhoneNumber = async (phoneNumber) => {
        this.onCloseDeletePhoneNumberModal()
        const voip_did_id = phoneNumber.voip_did_id
        this.setState({ processingAction: true })
        const response = await api.deleteNumber(voip_did_id)
        this.setState({ processingAction: false })
        if (response.status === 403) { // Attempted to delete a temp number
            this.setState({ pdcPromptOpen: true, pdcPromptColor: 'attention', pdcPromptMessage: 'This number is a temporary number for a transfer request. You can delete this number only if you cancel this transfer request.', pdcPromptTestId: 'deletion_error_re_temp_number' })
            return false
        } else if (response.error) { // Other error
            this.setState({ pdcPromptOpen: true, pdcPromptColor: 'attention', pdcPromptMessage: 'Oops, we had an issue, and our team has been notified. Try again later, or contact our support team if it\'s urgent.' })
            console.log(`Error from delete-number API call: ${response.error}`)
            return false
        } else { // Success
            this.props.deletePhoneNumber(phoneNumber)
            this.setState({ pdcPromptOpen: true, pdcPromptColor: 'tertiary', pdcPromptMessage: 'Number was deleted. If this was done by accident, or you wish to restore the number, contact our support team.', pdcPromptTestId: 'deletion_success_message' })
        }
    }

    editPhoneNumberCallerId = () => {
        console.log('pushed')
    }

    renderUnassignNumberDialog = () => {
        if (!this.state.unassignNumber) return
        let userName = ''
        let voipPhoneIds = []
        if (['Users', 'Direct Number'].includes(this.state.unassignNumber.type)) {
            const ringUsersAction = this.state.unassignNumber.call_rules.rule_groups[0].rules.actions.find(a => a.type === 'ring-users')
            voipPhoneIds = ringUsersAction.data.extensions.map(e => e.extension_id)
            userName = voipPhoneIds.map(this.getUserName).join(', ')
        }
        const modalContent = <div><br/>The phone number will be unassigned from the user and will not be available to them.</div>
        const modalTitle = `Unassign phone number ${this.state.unassignNumber ? formatPhoneNumber(this.state.unassignNumber.phone_number) : ''}${userName ? ` from ${userName}` : ''}?`

        return (
            <ConfirmModal
                title = {modalTitle}
                isShown = {this.state.showUnassignNumDialog}
                content = {modalContent}
                noButtonText = 'Cancel'
                yesButtonText = 'Unassign number'
                yesButtonColor = {this.state.pendingUnassignNum ? 'secondary' : 'primary'}
                noButtonDataTestId = 'unassign-reject-button'
                yesButtonDataTestId = 'unassign-confirm-button'
                confirmButtonDisabled = {this.state.pendingUnassignNum}
                rejectButtonDisabled = {this.state.pendingUnassignNum}
                onReject = {() => this.setState({ showUnassignNumDialog: false, unassignNumber: null })}
                onConfirm = {() => this.unassignNumber(voipPhoneIds)}
                size = 'size3'
            />
        )
    }

    getUserName = extensionId => {
        const user = this.props.users.find(user => user.extension.id === extensionId)
        return user ? `${user.first_name} ${user.last_name}` : ''
    }

    renderAction = phoneNumber => {
        const { classes } = this.props
        let action = <span></span>
        if (['Users', 'Direct Number'].includes(phoneNumber.type)) {
            const ringUsersAction = phoneNumber.call_rules.rule_groups[0].rules.actions.find(a => a.type === 'ring-users')
            const extensionIds = ringUsersAction.data.extensions.map(e => e.extension_id)
            action = <span>Ring {extensionIds.map((id, index) => {
                return (
                    <span key={index} className={`${!this.props.screenViewType.isMobileView ? classes.bold : ''}`}>
                        {this.getUserName(id)}{index < extensionIds.length - 1 ? ' + ' : ''}
                    </span>
                )
            })}</span>
        } else action = <span>{phoneNumber.type || ''}</span>
        return action
    }

    renderLoader = () => {
        const { classes } = this.props
        const isLoading = this.state.loadingPhoneNumbers || this.state.loadingUsers || this.state.pendingUnassignNum
        if (!isLoading) return null
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary[0]} size='bigger'/>
            </div>
        )
    }

    updateNumberRules = newNumberData => {
        const currentNumber = this.state.configureNumber
        currentNumber.call_rules.rule_groups = newNumberData.numberRulesGroups
        currentNumber.sms_forwarding = newNumberData.smsForwarding
        this.props.updatePhoneNumber(currentNumber)
    }

    updateCompanyExtensionRules = newRules => {
        this.setState({ companyExtensionRules: newRules })
    }

    onUpdateCallerNameSuccess = (voipDidId, callerName) => {
        const phoneNumber = this.props.phoneNumbers.items.find(pn => pn.voip_did_id === voipDidId)
        phoneNumber.caller_name = callerName
        this.props.updatePhoneNumber(phoneNumber)
    }

    renderConfigureNumber = routeProps => {
        const phoneNumber = routeProps.match.params.phone_number
        if (this.state.loadingPhoneNumbers || !this.props.users.length) return this.renderLoader()
        const companyExtension = this.companyExtension
            ? {
                id: this.companyExtension.extension_id,
                extension_number: this.companyExtension.extension,
                extension_name: this.companyExtension.extension_name
            }
            : null
        const configureNumber = this.state.configureNumber || this.props.phoneNumbers.items.find(pn => pn.phone_number === phoneNumber)
        return (
            <ConfigureCalling
                mainCompanyNumberVoipDidId = {this.state.mainCompanyNumberVoipDidId}
                updateMainCompanyNumberVoipDidId = {mainCompanyNumberVoipDidId => this.setState({ mainCompanyNumberVoipDidId })}
                users = {this.props.users}
                companyExtension = {companyExtension}
                phoneNumber = {configureNumber}
                updateNumberRules = {this.updateNumberRules}
                onClose = {this.closeConfigureNumber}
                updatePhoneNumber = {this.props.updatePhoneNumber}
                setBusy = {this.props.setBusy}
                schedules = {this.state.schedules}
                companyExtensionRules = {this.state.companyExtensionRules}
                updateCompanyExtensionRules = {this.updateCompanyExtensionRules}
                screenViewType = {this.props.screenViewType}
                subscribeForCHRNotifications = {this.props.subscribeForCHRNotifications}
                isOffline = {this.props.isOffline}
                phoneNumbers = {this.props.phoneNumbers}
            />
        )
    }

    renderEnableCallRecordingModal = () => {
        const showModal = Boolean(this.state.callRecordingNumberVoipDidId)
        if (!showModal) return null
        const phoneNumberVoipDidId = this.state.callRecordingNumberVoipDidId
        const phoneNumber = this.props.phoneNumbers.items.find(pn => pn.voip_did_id === phoneNumberVoipDidId)
        return (
            <EnableCallRecordingModal
                show = {true}
                phoneNumber = {phoneNumber.phone_number}
                onClose = {this.onCloseToggleCallRecordingModal}
                onConfirm = {() => this.toggleCallRecording(phoneNumberVoipDidId, true)}
            />
        )
    }

    renderDeletePhoneNumberModal = () => {
        const showModal = Boolean(this.state.deletePhoneNumberId)
        if (!showModal) return null
        const phoneNumberId = this.state.deletePhoneNumberId
        const phoneNumber = this.props.phoneNumbers.items.find(pn => pn.id === phoneNumberId)
        // Show both number and name, if they have non-whitespace differences
        const formattedNumber = formatPhoneNumber(phoneNumber.phone_number)
        const name = phoneNumber.name.split(' ').join('') === formattedNumber.split(' ').join('') ? '' : '- ' + phoneNumber.name
        const title = `Delete ${formattedNumber} ${name}?`
        const ack_msg = "This number will stop working, but you'll still have access to prior texts, voicemails, and call logs. Deleting a number cannot be undone."
        const unack_msg = ack_msg + ' Are you sure?'
        return (
            <ConfirmDeleteModal
                itemType='phone-number'
                isShown={true}
                acknowledgedTitle={title}
                notAcknowledgedTitle={title}
                acknowledgedMainContent={ack_msg}
                notAcknowledgedMainContent={unack_msg}
                onClose={this.onCloseDeletePhoneNumberModal}
                onDelete={() => this.deletePhoneNumber(phoneNumber)}
                newButtonDesigns={true} // This is temporary prop until we decide what we'll do with the colors
                titleTestId='delete_number_confirmation_title'
            />
        )
    }

    renderCallerIdEditModal = () => {
        const showModal = Boolean(this.state.editCallerIdNumberId)
        if (!showModal) return null
        const phoneNumberId = this.state.editCallerIdNumberId
        const phoneNumber = this.props.phoneNumbers.items.find(pn => pn.id === phoneNumberId)
        return (
            <EditCallerIdModal
                itemType='phone-number'
                isShown={true}
                acknowledgedTitle='Edit Caller Name'
                notAcknowledgedTitle='Edit Caller Name'
                acknowledgedMainContent=''
                notAcknowledgedMainContent=''
                onClose={this.onCloseEditCallerIdModal}
                onEdit={() => this.editPhoneNumberCallerId(phoneNumber)}
                newButtonDesigns={true} // This is temporary prop until we decide what we'll do with the colors
                defaultCompanyCallerId={this.state.defaultCompanyCallerId}
                currentCallerId={phoneNumber.caller_name}
                voipDidId={phoneNumber.voip_did_id}
                onSuccess={this.onUpdateCallerNameSuccess}
            />
        )
    }

    renderMyNumbersSubcomponent = () => {
        const MyNumbersSubcomponent = this.props.screenViewType.isMobileView ? MyNumbersSmall : MyNumbersLarge
        return (
            <MyNumbersSubcomponent
                users = {this.props.users}
                companyExtension = {this.companyExtension}
                screen = {this.state.screen}
                nameEdit = {this.state.nameEdit}
                hasMore = {this.state.hasMore}
                onToggleCallRecordingClick = {this.onToggleCallRecordingClick}
                getDropdownItems = {this.getDropdownItems}
                loadMore = {this.loadMore}
                updatePhoneNumber = {this.props.updatePhoneNumber}
                renderAction = {this.renderAction}
                changeValueInNumber = {this.changeValueInNumber}
                onConfigureNumberClick = {this.onConfigureNumberClick}
                hideCallRecording = {this.state.hideCallRecording}
            />
        )
    }

    render = () => {
        const basePath = this.getBasePath()
        const { classes } = this.props
        return (
            <>
                {this.renderLoader()}
                {this.state.processingAction ? <div className={classes.processingActionSpinnerWrapper} data-test-id='my_numbers_spinner'><Spinner/></div> : null}
                <Switch>
                    <Route path={`${basePath}/:phone_number`} render={this.renderConfigureNumber}/>
                    <Route path={basePath} render={this.renderMyNumbersSubcomponent}/>
                </Switch>
                {this.renderEnableCallRecordingModal()}
                {this.renderDeletePhoneNumberModal()}
                {this.renderCallerIdEditModal()}
                {this.renderUnassignNumberDialog()}
                <Prompt isOpen={this.state.pdcPromptOpen} color={this.state.pdcPromptColor} position='bottom' onClose={this.handlepdcPromptClose} content={this.state.pdcPromptMessage} testId={this.state.pdcPromptTestId} />
            </>
        )
    }
}

MyNumbers.propTypes = {
    // Material ui classes
    classes: PropTypes.object,
    phoneNumbers: PropTypes.object,
    users: PropTypes.array,
    extensions: PropTypes.array,
    newPurchase: PropTypes.bool,
    resetPhoneNumbers: PropTypes.func,
    addPhoneNumbers: PropTypes.func,
    updatePhoneNumber: PropTypes.func,
    deletePhoneNumber: PropTypes.func,
    routeProps: PropTypes.object,
    screenViewType: PropTypes.object,
    setBusy: PropTypes.func,
    smallView: PropTypes.string,
    subscribeForCHRNotifications: PropTypes.func,
    isOffline: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyNumbers))
