<template>
    <v-simple-table id="stats-table" data-test-id="per-num-graph-table">
        <thead data-test-id="table-head">
            <tr data-test-id="head-row">
                <th data-test-id="number">{{ $lang.t('app.number', 'Number') }}</th>
                <th v-for="(prop, i) in Object.keys(items)" :data-test-id="prop" :key="prop">
                    {{ fields[prop].translation }}
                </th>
            </tr>
        </thead>
        <tbody data-test-id="table-body">
            <tr
                v-for="(item, i) in numbers"
                :data-test-id="i"
                :key="i"
            >
                <td
                    :class="{disabled: !item}"
                    data-test-id="number"
                >
                    {{ item || '&mdash;' }}
                </td>
                <td v-for="prop in Object.keys(items)" :data-test-id="prop" :key="prop">
                    <HorizontalBar
                        :color="colors[item]"
                        :item="items[prop].find(
                            (x) => x.number === item
                        )"
                        :title="`${item}: ${items[prop].find(
                            (x) => x.number === item
                        ).value}`"
                        :max="Math.max.apply(
                            Math,
                            items[prop].map((x) => x.display_value)
                        )"
                        :data-test-id="`per-num-graph-item-${i}-bar`"
                    />
                </td>
            </tr>
            <tr data-test-id="per-num-bottom-graph">
                <td data-test-id="empty-cell"><br/></td>
                <td v-for="prop in Object.keys(items)" :data-test-id="prop" :key="`bottom-${prop}`">
                    <div class="d-flex justify-space-between">
                        <span class="min">
                            {{
                                Math.round(
                                    formatters[fields[prop]['type']](
                                        Math.min.apply(
                                            Math,
                                            items[prop].map((x) => x.value)
                                        )
                                    )
                                )
                            }}
                        </span>
                        {{ $lang.t('app.calls-lowercase', 'calls') }}
                        <span class="max">
                            {{
                                Math.round(
                                    formatters[fields[prop]['type']](
                                        Math.max.apply(
                                            Math,
                                            items[prop].map((x) => x.value)
                                        )
                                    )
                                )
                            }}
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
import { OrangeColor } from 'orange-colors';
import { primary_color } from '../../../libs/helpers';
import HorizontalBar from '../../elements/HorizontalBar.vue';

export default {
    components: {
      HorizontalBar,
    },
    props: {
        items: {},
        numbers:{},
        logarithmic:{},
        formatters:{},
        fields:{},
    },
    data() {
      return {
        // logarithmic: false,
        loading: false,
        colors: {},
      };
    },
    created() {
        this.prepareNumbers();
        for (const prop of Object.keys(this.$props.items)) {
            if (this.$props.items[prop].every((x) => !x.value)) delete this.$props.items[prop];
        }
        if (!Object.keys(this.$props.items)) return null;
        return true;

    },
    methods: {
        async prepareNumbers() {
            for (const number of this.$props.numbers) {
                let num = number.replace(/\D/g, '');
                num = Math.round(Number.parseInt(num) / 10000);
                this.$data.colors[number] = this.colorNumbers(num);
            }
        },
        colorNumbers(number) {
            const c = new OrangeColor(primary_color(this));
            if (Number.parseInt(number)) {
              c.h = (Number.parseInt(number) % 18) * 20;
            } else {
              c.s = 0;
            }
            return c.html_string;
        },
    }
}
</script>