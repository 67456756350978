/**
 * @param {object} theme
 */
const styles = theme => ({
    appBar: {
        backgroundColor: theme.palette.paper.gray,
        borderBottom: `1px solid ${theme.palette.secondary.background}`,
        boxShadow: 'unset',
        zIndex: 1100
    },
    toolbar: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 57,
        left: 'env(titlebar-area-x, 0)',
        /* Use the environment variable for the top anchoring with a fallback. */
        top: 'env(titlebar-area-y, 0)',
        /* Use the environment variable for setting the width with a fallback. */
        width: 'env(titlebar-area-width, 100%)',
        /* Use the environment variable for setting the height with a fallback. */
        height: 'env(titlebar-area-height, 33px)',
        '& > .top-bar-left': {
            display: 'flex',
            alignItems: 'center',
            '& > .menu-button': {
                height: 57,
                width: 50,
                minWidth: 50,
                display: 'grid',
                placeItems: 'center',
                backgroundColor: theme.palette.primary.main,
                cursor: 'pointer',
                '& > svg': {
                    fontSize: 24,
                    color: 'white'
                }
            },
            '& > .search-button': {
                padding: '10px 6px',
                width: 340,
                height: 40,
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                border: `1px solid ${theme.palette.secondary.border}`,
                borderRadius: 20,
                color: theme.palette.text.secondary,
                display: 'flex',
                alignItems: 'center',
                columnGap: 8,
                cursor: 'pointer',
                '&.sm': {
                    width: 250
                },
                '&:hover': {
                    backgroundColor: theme.palette.secondary.background
                },
                '& > svg': {
                    fontSize: 20
                }
            }
        },
        '& > .top-bar-right': {
            display: 'flex',
            alignItems: 'center',
            columnGap: 12,
            paddingRight: 15,
            '& > .active-call': {
                height: 50,
                borderRadius: 3,
                justifyContent: 'space-between',
                width: 273
            },
            '& .dialpad-button': {
                backgroundColor: theme.palette.primary.main,
                width: 34,
                height: 34,
                padding: 0,
                '& svg': {
                    fontSize: 20,
                    color: 'white'
                },
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.secondary.background
                }
            },
            '& > .delimiter': {
                width: 1,
                height: 34,
                backgroundColor: theme.palette.secondary.light
            },
            '& > .do-not-disturb-wrapper': {
                cursor: 'pointer',
                '& > svg': {
                    color: theme.palette.secondary.light,
                    fontSize: 34
                },
                '&.on > svg': {
                    color: theme.palette.info.dark
                }
            }
        }
    },
    identityWrapper: {
        position: 'relative',
        marginLeft: 10,
        '& > .identity': {
            display: 'flex',
            alignItems: 'center',
            columnGap: 10,
            width: 225,
            minWidth: 225,
            padding: '9px 0 10px',
            cursor: 'pointer',
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                '& > span, & > p': {
                    color: `${theme.palette.text.primary} !important`
                }
            }
        }
    },
    identityPopup: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: 280,
        background: 'white',
        color: 'black',
        overflowY: 'auto',
        top: 20,
        zIndex: 10001,
        transform: 'translateX(100%)',
        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.2), 0px 20px 35px rgba(0, 0, 0, 0.15), 0px 10px 45px rgba(0, 0, 0, 0.1)',
        borderRadius: 30,
        transition: 'opacity .2s',
        '& .identity-popup-actions': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 5,
            marginBottom: 5,
            padding: '25px 0',
            '& svg': {
                marginTop: 10
            }
        },
        '& .x-icon-wrapper': {
            position: 'absolute',
            top: 22,
            right: 22,
            padding: 12.45,
            lineHeight: 1,
            borderRadius: '50%',
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.secondary[-400]
            },
            '& .x-icon': {
                width: 18.41,
                height: 18.41,
                color: theme.palette.secondary[0]
            }
        },
        '&.mobile-view': {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            position: 'fixed',
            transform: 'unset',
            width: 'unset',
            borderRadius: 'unset'
        }
    },
    identityPopupUserInfo: {
        paddingBottom: 14,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 0 15px',
        '& > div': {
            marginBottom: 10
        },
        '& .main-text': {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: '19px'
        },
        '& .secondary-text': {
            fontSize: 11,
            fontWeight: 500,
            lineHeight: '17px',
            letterSpacing: 0.05,
            color: theme.palette.secondary['+100'],
            marginTop: 5
        },
        '&.mobile-view': {
            paddingBottom: 70,
            '& > div': {
                marginBottom: 15
            },
            '& .main-text': {
                fontSize: 20,
                lineHeight: '28px'
            },
            '& .secondary-text': {
                fontSize: 16,
                lineHeight: '24px',
                marginTop: 5
            }
        }
    },
    identityPopupOptions: {
        maxHeight: 'max(40vh, 150px)',
        overflowY: 'auto',
        '& .identity-popup-option': {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: '38px',
            color: theme.palette.primary[0],
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.primary[-500]
            }
        },
        '&.mobile-view': {
            '& .identity-popup-option': {
                padding: 0,
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '51px'
            }
        }
    },
    identityPopupDesktopPosition: {
        right: -20
    },
    dialogPaper: {
        margin: 0,
        width: '100%',
        height: '100%',
        maxHeight: '100%'
    },
    activeCall: {
        backgroundColor: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 46,
        minHeight: 46,
        borderRadius: 0,
        justifyContent: 'center',
        columnGap: 10,
        color: 'white',
        paddingLeft: 12,
        paddingRight: 12,
        marginRight: 3,
        cursor: 'pointer',
        '& > .call-info': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 1,
            '& > .name-and-duration': {
                display: 'flex',
                alignItems: 'center',
                columnGap: 8,
                '& > p': {
                    maxWidth: 110,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }
            },
            '& > .participants': {
                display: 'flex',
                alignItems: 'center',
                columnGap: 10,
                '& > div': {
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 5,
                    '& > svg': {
                        fontSize: 18,
                        color: theme.palette.secondary.light
                    }
                }
            }
        },
        '& > .call-actions': {
            display: 'flex',
            alignItems: 'center',
            columnGap: 10,
            '& > button': {
                width: 32,
                minWidth: 32,
                height: 32,
                minHeight: 32,
                borderRadius: '50%',
                border: 'none',
                cursor: 'pointer',
                position: 'relative',
                '& > svg': {
                    fontSize: 18,
                    color: 'white',
                    position: 'absolute',
                    top: '8px',
                    left: '8px'
                },
                '&.mute-button': {
                    backgroundColor: theme.palette.primary.main,
                    '&.muted': {
                        backgroundColor: theme.palette.secondary.main
                    }
                },
                '&.hangup-button': {
                    backgroundColor: theme.palette.error.main
                }
            }
        }
    },
    tooltipPopper: {
        width: 211
    },
    tooltip: {
        padding: '8px 12px',
        textAlign: 'center'
    }
})

export default styles
