import React, { Component } from 'react'
import HeaderTabs from 'header-tabs'
import MyNumbers from './MyNumbers/MyNumbers'
import AddANumber from './AddANumber/AddANumber.tsx'
import MyTransferNumbers from './MyTransferNumbers/MyTransferNumbers'
import TransferMyNumber from './TransferMyNumber/TransferMyNumber'
import ConfirmModal from 'confirm-modal'
import { AddNumberIcon, CallOutgoingIcon, PhoneCallingIcon, PhoneNumberIcon } from 'svg-icons'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getCurrentTabFromRoute } from 'app-tabs'
import PropTypes from 'prop-types'
import TeammateProvider, { TeammateConsumer } from 'providers/src/MyPhoneContact/TeammateProvider'
import { withStyles } from '@material-ui/core'

const styles = () => ({
    appWrapper: {
        flex: 1,
        display: 'flex',
        height: '100%',
        position: 'relative',
        width: '100%',
        flexDirection: 'column',
        '& *': {
            '&::-webkit-scrollbar': {
                '-webkit-appearance': 'none !important',
                width: 6,
                height: 6
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'var(--black-transparent-20,rgba(0,0,0,.2))',
                borderRadius: 4
            }
        }
    },
    appContent: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100% - 60px)',
        width: '100%',
        '&.mobile-view': {
            height: 'calc(100% - 50px)'
        }
    }
})

class App extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isBusy: false,
            newPurchase: false,
            badges: { 'transfer-a-number': this.props.unreadPorting === '1' ? '!' : '' }
        }
        this.tabs = [
            { id: 'my-numbers', icon: PhoneNumberIcon, default: true },
            { id: 'add-numbers', icon: AddNumberIcon },
            this.props.hasActivePorts && { id: 'transfer-requests', icon: PhoneCallingIcon },
            { id: 'transfer-numbers', icon: CallOutgoingIcon }
        ].filter(Boolean)
        const currentTab = getCurrentTabFromRoute('phone-numbers', this.props.routeProps, this.tabs)
        if (!currentTab) {
            const currentTab = this.tabs.find(t => t.default).id
            this.updateRouterHistory(currentTab)
        }
        this.state = {
            isBusy: false,
            newPurchase: false,
            badges: { 'transfer-numbers': this.props.unreadPorting === '1' ? '!' : '' }
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.unreadPorting !== this.props.unreadPorting) {
            const newBadges = this.state.badges
            newBadges['transfer-numbers'] = this.props.unreadPorting === '1' ? '!' : ''
            this.setState({ badges: { ...newBadges } })
        }
    }

    onTabSwitch = async newTab => {
        if (newTab === 'transfer-numbers') {
            const namespace = 'porting'
            const value = '0'
            this.props.setBadgeValue(namespace, value)
        }
        const currentTab = getCurrentTabFromRoute('phone-numbers', this.props.routeProps, this.tabs)
        if (currentTab === newTab) return
        const isBusyState = this.state.isBusy
        const busy = typeof isBusyState === 'object' ? isBusyState.busy : isBusyState
        if (busy) {
            this.setState({ exitAttempt: newTab })
            return false
        }
        this.updateRouterHistory(newTab)
    }

    getBasePath = () => this.props.routeProps.match.path.replace(/\/+$/g, '')

    updateRouterHistory = tabName => {
        const basePath = this.getBasePath()
        this.props.routeProps.history.push(`${basePath}/${tabName}`)
    }

    getCurrentTab = () => {
        let currentTab = ''
        const pathnameSplit = window.location.pathname.split('/')
        if (pathnameSplit.length > 2) currentTab = pathnameSplit[2]
        if (!this.tabs.map(t => t.id).includes(currentTab)) return 'my-numbers'
        return currentTab
    }

    setPathnameTab = tabName => {
        window.history.replaceState(`Personal settings tab: ${tabName}`, 'Personal Settings Tab Switched', `/phone-numbers/${tabName}`)
    }

    setBusy = isBusy => {
        this.setState({ isBusy })
        this.props.setHasChange(isBusy)
    }

    setTransferWizardComplete = () => {
        if (!this.tabs.find(t => t.id === 'transfer-requests')) {
            this.tabs.splice(2, 0, { id: 'transfer-requests', icon: PhoneCallingIcon })
        }
        this.props.setHasActivePorts(true)
    }

    renderApp = () => {
        const basePath = this.getBasePath()
        const defaultTabId = this.tabs.find(t => t.default).id
        const { isMobileView } = this.props.screenViewType
        return (
            <Switch>
                <Route path={`${basePath}/my-numbers`} render={props => (
                    <TeammateConsumer>
                        {teammates => (
                            <MyNumbers
                                setBusy = {this.setBusy}
                                extensions = {this.props.extensions}
                                users = {Array.from(teammates.teammates.items.values())}
                                newPurchase = {this.state.newPurchase}
                                routeProps = {props}
                                screenViewType = {this.props.screenViewType}
                                subscribeForCHRNotifications = {this.props.subscribeForCHRNotifications}
                                isOffline = {this.props.isOffline}
                            />
                        )}
                    </TeammateConsumer>
                )}/>
                <Route path={`${basePath}/add-numbers`} render={() => <AddANumber setBusy={this.setBusy} numbersBasePath={basePath} /* FI - TODO: Remove this switchTab */ switchTab={this.onTabSwitch}/>}/>
                <Route path={`${basePath}/transfer-requests`} render={(props) => <MyTransferNumbers setBusy={this.setBusy} updateRouterHistory={this.updateRouterHistory} screenViewType = {this.props.screenViewType} routeProps={props}/> } />
                <Route path={`${basePath}/transfer-numbers`} render={() => {
                    if (this.state.redirectPath) {
                        const redirectPath = this.state.redirectPath
                        this.unsetRedirectPath()
                        return <Redirect to={`${basePath}/${redirectPath}`} />
                    }

                    return <TransferMyNumber smallView={isMobileView} screenViewType={this.props.screenViewType} setBusy={this.setBusy} setRedirectPath={this.setRedirectPath} setTransferWizardComplete={this.setTransferWizardComplete}/>
                }
                }/>
                <Route path='/' render={() => <Redirect to={`${basePath}/${defaultTabId}`}/>}/>
            </Switch>
        )
    }

    setRedirectPath = (path) => {
        this.setState({ redirectPath: path })
    }

    unsetRedirectPath = () => this.setState({ redirectPath: null })

    discardChanges = () => {
        const newTab = this.state.exitAttempt
        this.setBusy(false)
        this.setState({ exitAttempt: null }, () => this.onTabSwitch(newTab))
    }

    renderDiscardChangesModal = () => {
        const isBusy = this.state.isBusy
        const defaultTitle = 'Discard changes?'
        const defaultContent = null
        const cancelDefaultText = 'Cancel'
        const yesDefaultText = 'Discard'
        return (
            <ConfirmModal
                isShown = {Boolean(this.state.exitAttempt)}
                title = {typeof isBusy === 'object' ? isBusy.title || defaultTitle : defaultTitle}
                content = {typeof isBusy === 'object' ? isBusy.content || defaultContent : defaultContent}
                noButtonText = {typeof isBusy === 'object' ? isBusy.cancelText || cancelDefaultText : cancelDefaultText}
                yesButtonText = {typeof isBusy === 'object' ? isBusy.yesText || yesDefaultText : yesDefaultText}
                yesButtonColor = 'attention'
                onReject = {() => this.setState({ exitAttempt: null })}
                onConfirm = {this.discardChanges}
                size = 'size2' // size440
            />
        )
    }

    render = () => {
        const { classes } = this.props
        const currentTab = getCurrentTabFromRoute('phone-numbers', this.props.routeProps, this.tabs)
        const { isMobileView } = this.props.screenViewType
        return (
            <TeammateProvider>
                <div className={classes.appWrapper}>
                    <HeaderTabs smallView={isMobileView} selectedTabId={currentTab} tabs={this.tabs} onTabSwitch={this.onTabSwitch} unreadPorting={this.props.unreadPorting} badges={this.state.badges}/>
                    <div className={`${classes.appContent} ${isMobileView ? 'mobile-view' : ''}`}>
                        {this.renderApp()}
                        {this.renderDiscardChangesModal()}
                    </div>
                </div>
            </TeammateProvider>
        )
    }
}

App.propTypes = {
    hasActivePorts: PropTypes.bool,
    setHasActivePorts: PropTypes.func,
    unreadPorting: PropTypes.string,
    routeProps: PropTypes.any,
    screenViewType: PropTypes.any,
    setBadgeValue: PropTypes.func,
    setHasChange: PropTypes.func,
    extensions: PropTypes.array,
    classes: PropTypes.any,
    subscribeForCHRNotifications: PropTypes.func,
    isOffline: PropTypes.bool
}

export default withStyles(styles)(App)
