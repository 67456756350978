import lang from 'lang'
import { formatTime, formatPhoneNumber } from 'formatters'
import SmartFilters from 'console/src/models/SmartFilters'
import CsvItemsDownloader from 'console/src/libs/csv-helpers/ItemsDownloader'

/**
 *
 */
export default class SMS extends SmartFilters {
    /**
     *
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(session, component, '/sms')
        this.filters = {
            from: null,
            to: null,
            direction: null,
            type: 'forever'
        }
        this.apply_type_range()
        this.setup = {}
        this.headers = {
            id: lang.t('sms.message-id', 'Message ID'),
            extension: lang.t('app.extension', 'Extension'),
            from: lang.t('app.from', 'From'),
            to: lang.t('app.to', 'To'),
            direction: lang.t('app.direction', 'Direction'),
            status: lang.t('sms.delivery-status', 'Delivery status'),
            sent_at: lang.t('app.sent-at', 'Sent at')
        }
        this.config_key = 'sms-filters'
        this.empty_filters = JSON.parse(JSON.stringify(this.filters))
        /* eslint-disable */
        this.functions = {
			extension: (item) => {
                if (!item['extension']) return '—';
                return item['extension'].extension;
            },
            from: (item) => formatPhoneNumber(item['from']).replace(/-/g, ''),
            to: (item) => formatPhoneNumber(item['to']).replace(/-/g, ''),
            sent_at: (item) => formatTime(item['sent_at']).replace(',', ' '),
        };
		/* eslint-enable */
    }

    /**
     *
     * @param {object} params
     */
    async loadItems (params) {
        this.loading = true
        let { uri } = this
        if (!this.extension) {
            if (uri.includes('?')) {
                uri = `${uri}&request_all_extensions=1`
            } else {
                uri = `${uri}?request_all_extensions=1`
            }
        }
        const cache = this.get_cached_filters()
        if (cache) {
            const tzoffsetStart = (new Date(cache.start * 1000)).getTimezoneOffset() * 60000
            const tzoffsetEnd = (new Date(cache.end * 1000)).getTimezoneOffset() * 60000
            this.filters.start = (new Date((cache.start * 1000) - tzoffsetStart)).toISOString().slice(0, -1)
            this.filters.end = (new Date((cache.end * 1000) - tzoffsetEnd)).toISOString().slice(0, -1)
            uri += `&filters[created_at]=gte:${cache.start}&filters[created_at]=lte:${cache.end}`
        }
        try {
            const items = await this.session.get_list(
                uri, params.limit, params.offset
            )
            this.items = SMS.prepareMessages(items.items)
            this.hideFiltersIfNeeded(items.items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
            this.filters_applied = true // to open filters on page loading, to be consistent with other reports
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    static prepareMessages (items) {
        const messages = []
        for (const item of items) {
            for (const to of item.to) {
                const newItem = item
                newItem.to = to.number
                newItem.sent_at = to.sent_at
                newItem.status = to.status
                messages.push(newItem)
            }
        }

        return messages
    }

    /**
     *
     */
    apply_filters () {
        let uri = `${this.baseUri}?mode=full`
        let start, end
        if (this.filters.from) {
            uri += `&filters[from]=${this.filters.from}`
        }
        if (this.filters.to) {
            uri += `&filters[to]=${this.filters.to}`
        }
        if (this.filters.direction) {
            uri += `&filters[direction]=${this.filters.direction}`
        }
        if (this.filters.start) {
            start = Math.floor(Date.parse(this.filters.start) / 1000)
            uri += `&filters[created_at]=gte:${start}`
        }
        if (this.filters.end) {
            end = Math.floor(Date.parse(this.filters.end) / 1000)
            uri += `&filters[created_at]=lte:${end}`
        }
        const stored_filters = {
            start,
            end,
            type: this.filters.type
        }
        this.cachier.setItem(this.reports_filters_cache_key, stored_filters)
        this.filters_applied = true
        this.uri = uri
    }

    /**
     *
     */
    async generate_csv () {
        this.component.emit('hidePagination')
        try {
            this.csv_downloader = new CsvItemsDownloader(this.session)
            const res = await this.csv_downloader.get_list_all(this.uri)
            if (res === 'aborted') {
                this.csv_downloader = null
                return null
            }
            if (!this.csv_downloader.stop) {
                SMS.download_csv(
                    this.build_csv(SMS.prepareMessages(res.items)),
                    'sms-report.csv'
                )
                this.csv_downloaded_successfully()
            }
        } catch (err) {
            this.validation_error(err)
        }
        this.csv_downloader = null
        this.component.emit('showPagination')

        return true
    }
}
